import { cn, parseIdentifier } from '~/utils'
import { Text } from '@radix-ui/themes'
import {
  HiOutlineChatBubbleLeftEllipsis,
  HiOutlineChatBubbleLeftRight,
  HiOutlineCommandLine,
  HiOutlineComputerDesktop,
  HiOutlineGlobeAlt,
} from 'react-icons/hi2'
import { LuPanelTop, LuSquareStack, LuWorkflow } from 'react-icons/lu'
import { FC, useMemo, type ComponentProps, type ReactNode } from 'react'
import { HiOutlineUser } from 'react-icons/hi'
import { Color } from '../types'
import { IdentifierDropdownMenu } from '~/componentsV2/IdentifierDropdownMenu'
import { Button } from '@bpinternal/ui-kit'

type Props = {
  id: string
  className?: string
  prefix?: ReturnType<typeof parseIdentifier>
  color?: Color
  path?: string
  highContrast?: boolean
  studioUrl?: string
  fallback?: ReactNode
} & Pick<ComponentProps<typeof IdentifierDropdownMenu>, 'logsStartDate'>

export const Identifier = ({
  className,
  id,
  prefix: providedPrefix,
  color = 'gray',
  path,
  highContrast,
  fallback,
  studioUrl,
  logsStartDate,
  ...props
}: Props) => {
  const prefix = providedPrefix ?? parseIdentifier(id)

  const prefixIconMap: Partial<Record<ReturnType<typeof parseIdentifier>, FC<{ className?: string }>>> = useMemo(() => {
    return {
      conv: HiOutlineChatBubbleLeftRight,
      msg: HiOutlineChatBubbleLeftEllipsis,
      evt: HiOutlineComputerDesktop,
      user: HiOutlineUser,
      url: HiOutlineGlobeAlt,
      wf: LuWorkflow,
      nd: LuPanelTop,
      ins: LuSquareStack,
    }
  }, [id])

  const Icon = prefixIconMap[prefix] ?? HiOutlineCommandLine

  if (prefix === 'unknown') {
    return <>{fallback ?? id}</>
  }

  return (
    <IdentifierDropdownMenu
      path={path}
      value={id}
      prefix={prefix}
      studioUrl={studioUrl}
      logsStartDate={logsStartDate}
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        {...props}
        variant="ghost"
        data-accent-color={color}
        className={cn(
          'group -mx-1 cursor-pointer rounded-sm p-1 hover:bg-accent-2',
          highContrast ? 'hover:bg-accent-3' : 'hover:bg-accent-2',
          className
        )}
        leading={<Icon className={cn('flex-none', highContrast ? 'text-accent-12' : 'text-accent-11')} />}
      >
        <Text
          size={'1'}
          className={cn(
            'font-mono underline decoration-dashed',
            highContrast
              ? 'text-accent-12 decoration-accent-9 group-hover:decoration-accent-11'
              : 'text-accent-11  decoration-accent-8 group-hover:decoration-accent-10 '
          )}
        >
          {id}
        </Text>
      </Button>
    </IdentifierDropdownMenu>
  )
}
