import { Outlet, createFileRoute } from '@tanstack/react-router'
import { Permission } from '../../features/users/types'
import { isAuthorized } from '../../features/users/services'
import { useAppStore, useLocalStore } from '~/stores'
import { BannerPortal, Boundary } from '../../componentsV2'
import { Link } from '@radix-ui/themes'
import { WorkspaceUsageCallout } from '../../features/billing/componentsV2/WorkspaceUsageCallout'
import { UnpaidInvoiceCallout } from '../../features/billing/componentsV2/UnpaidInvoiceCallout'
import { useIsAuthorized } from '~/hooks'
import { config } from '~/shared'
import { setTag } from '@sentry/react'
import { getQueryOptions } from '~/services'
import { Callout } from '@bpinternal/ui-kit'

export const Route = createFileRoute('/workspaces/$workspaceId')({
  beforeLoad: async ({ context, params }) => {
    const members = await context.queryClient.ensureQueryData(
      getQueryOptions('workspaces_/$workspaceId_/members', { workspaceId: params.workspaceId })
    )
    const workspaceMember = members?.find((member) => member.userId === context.user.id)
    const isUserAuthorized = (permission: Permission): boolean | undefined => {
      if (!workspaceMember) {
        return undefined
      }

      return isAuthorized({ permission, role: workspaceMember?.role })
    }
    return { isUserAuthorized }
  },
  loader: async ({ context, params }) => {
    const { setActiveWorkspace } = useAppStore.getState()
    const { setLastActiveWorkspaceId } = useLocalStore.getState()
    const workspace = await context.queryClient.fetchQuery(
      getQueryOptions('workspaces_/$workspaceId_', { workspaceId: params.workspaceId })
    )
    setActiveWorkspace(workspace)
    setLastActiveWorkspaceId(workspace.id)
  },
  component: Component,
})

function Component() {
  const { workspaceId } = Route.useParams()
  const { user } = Route.useRouteContext()
  const hasAccess = useIsAuthorized({ workspaceId, userId: user.id })
  setTag('workspaceId', workspaceId)
  return (
    <>
      <BannerPortal>
        <Boundary loader={null} onError={() => null}>
          {hasAccess('workspace.manageBilling') && <UnpaidInvoiceCallout workspaceId={workspaceId} />}
          <WorkspaceUsageCallout workspaceId={workspaceId} />
        </Boundary>
        {!user.emailVerified && (
          <Callout className="m-2">
            Your email address is not verified. Email verification will be enforced soon.{' '}
            <Link target="_blank" href={`${config.ssoBaseUrl}/verification`}>
              Verify now
            </Link>
          </Callout>
        )}
      </BannerPortal>
      <Outlet />
    </>
  )
}
