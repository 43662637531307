import { showConfirmationPrompt } from '@bpinternal/ui-kit'
import { Box, Flex, Text } from '@radix-ui/themes'
import { useNavigate } from '@tanstack/react-router'
import { capitalize } from 'lodash'
import { UpgradeBadge } from '~/elementsv2/UpgradeBadge'
import { Plan } from '~/features/usage/useBilling'

export const useMonetizationPrompt = (feature: string, workspaceId: string) => {
  const navigate = useNavigate()

  const triggerMonetizationPrompt = async (targetPlan: Plan) => {
    const targetPlanCapitalized = capitalize(targetPlan)

    const navigateToUpgradePage = await showConfirmationPrompt(
      <Box>
        <Text size="2" as="p">
          {feature} is only available on <strong>{targetPlanCapitalized}</strong> or higher plans.
        </Text>
      </Box>,
      {
        title: `Plan Upgrade Required`,
        cancelLabel: 'Not now',
        confirmLabel: (
          <Flex align="center" justify="between" gap="4px">
            <UpgradeBadge />
            <Text size="2" as="span">
              Upgrade
            </Text>
          </Flex>
        ),
        variant: 'neutral',
        showCancel: true,
      }
    )
    if (navigateToUpgradePage) {
      void navigate({
        to: '/workspaces/$workspaceId/billing/plans',
        params: { workspaceId },
      })
    }
  }

  return triggerMonetizationPrompt
}
