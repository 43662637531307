import type Stripe from 'stripe'
import { DateTime } from 'luxon'
import {
  COMMUNITY_PLAN_PRODUCT_ID,
  PLUS_PLAN_PRODUCT_ID,
  ProductWithPrices,
  TEAM_PLAN_PRODUCT_ID,
  type ProductQuantityMap,
} from '../usage/useBilling'
import { QuotaType } from '@bpinternal/const'
import { dollars } from '~/utils'

export const formatPhases = (phases: Stripe.SubscriptionSchedule.Phase[], products: ProductWithPrices[]) => {
  return phases.map((phase) => {
    const phaseItems = phase.items
      .map((phaseItem) => {
        const product = products?.find((product) => product.prices.some((price) => price.id === phaseItem.price))
        const price = product?.prices.find((price) => price.id === phaseItem.price)

        return {
          productId: product?.id ?? '',
          name: product?.name ?? `Product ${phaseItem.price}`,
          quotaName: product?.metadata.quotaName as QuotaType,
          quantity: phaseItem.quantity ?? 0,
          usageType: price?.recurring?.usage_type,
        }
      })
      .filter((item) => item.usageType === 'licensed')
      .sort((a, b) => {
        const aIsPlan = a.name.includes('Plan')
        const bIsPlan = b.name.includes('Plan')

        if (aIsPlan && !bIsPlan) return -1
        if (!aIsPlan && bIsPlan) return 1

        return a.name.localeCompare(b.name)
      })
    return {
      start_date: DateTime.fromSeconds(phase.start_date).setZone('utc').toFormat('MMM dd, yyyy'),
      end_date: DateTime.fromSeconds(phase.end_date).setZone('utc').toFormat('MMM dd, yyyy'),
      items: phaseItems,
    }
  })
}

export const formatSubItems = (subItems: Stripe.SubscriptionItem[], products: ProductWithPrices[]) => {
  return subItems
    .filter((subItem) => subItem.price.recurring?.usage_type === 'licensed')
    .map((subItem) => {
      const product = products?.find((product) => product.prices.some((price) => price.id === subItem.price.id))

      return {
        productId: subItem.price.product.toString(),
        name: product?.name ?? ``,
        quotaName: product?.metadata.quotaName as QuotaType,
        quantity: subItem.quantity ?? 0,
      }
    })
    .sort((a, b) => {
      const aIsPlan = a.name.includes('Plan')
      const bIsPlan = b.name.includes('Plan')

      if (aIsPlan && !bIsPlan) return -1
      if (!aIsPlan && bIsPlan) return 1

      return a.name.localeCompare(b.name)
    })
}

export const removePlansFromProductMap = (productQuantityMap: ProductQuantityMap): ProductQuantityMap => {
  const planProductIds = [TEAM_PLAN_PRODUCT_ID, PLUS_PLAN_PRODUCT_ID, COMMUNITY_PLAN_PRODUCT_ID]

  return Object.fromEntries(
    Object.entries(productQuantityMap).filter(([, value]) => !planProductIds.includes(value.productDetails.id))
  )
}

export const isSubscribedToPrice = (priceId: string, subscriptions: Stripe.Subscription[]): boolean => {
  if (!subscriptions?.length || !subscriptions[0]?.items.data.length) return false
  return subscriptions.some((subscription) => subscription.items.data.some((item) => item.price.id === priceId))
}

export const getSubscriptionForPrice = (
  priceId: string,
  subscriptions: Stripe.Subscription[]
): Stripe.Subscription | null => {
  if (!subscriptions?.length || !subscriptions[0]?.items.data.length) return null

  for (const subscription of subscriptions) {
    if (subscription.items.data.some((item) => item.price.id === priceId)) {
      return subscription
    }
  }

  return null
}

export const getPlanPricing = (productId: string, isYearly: boolean, productQuantityMap: ProductQuantityMap) => {
  const product = productQuantityMap[productId]!
  const monthlyPrice = product?.productDetails.prices.find((price) => price.recurring?.interval === 'month')
  const yearlyPrice = product?.productDetails.prices.find((price) => price.recurring?.interval === 'year')

  const currentPrice = isYearly ? yearlyPrice : monthlyPrice
  const price = (isYearly ? (yearlyPrice?.unit_amount ?? 0) : (monthlyPrice?.unit_amount ?? 0)) / 100
  const pricePerMonth = dollars(isYearly ? price / 12 : price)

  const pricePerYear = isYearly ? price : undefined

  return { price: currentPrice, pricePerMonth, pricePerYear }
}

const isUpgradingPlans = (
  fromPrice: Stripe.Price,
  toPrice: Stripe.Price,
  planProductIds: { team: string; plus: string; community: string }
): boolean => {
  const planRank = {
    [planProductIds.community]: 0,
    [planProductIds.plus]: 1,
    [planProductIds.team]: 2,
  }

  const fromProductId = fromPrice.product.toString()
  const toProductId = toPrice.product.toString()

  const fromPlanRank = planRank[fromProductId]
  const toPlanRank = planRank[toProductId]

  if (typeof fromPlanRank !== 'number' || typeof toPlanRank !== 'number') {
    throw new Error(`Invalid plan product id: ${fromProductId} or ${toProductId}`)
  }

  const isPlanUpgrading = toPlanRank > fromPlanRank

  const isIntervalUpgrading = fromPrice.recurring?.interval === 'month' && toPrice.recurring?.interval === 'year'

  return isPlanUpgrading || isIntervalUpgrading
}

export const isDowngradeFromYearly = (fromPrice: Stripe.Price, toPrice: Stripe.Price): boolean => {
  const isUpgrading = isUpgradingPlans(fromPrice, toPrice, {
    team: TEAM_PLAN_PRODUCT_ID,
    plus: PLUS_PLAN_PRODUCT_ID,
    community: COMMUNITY_PLAN_PRODUCT_ID,
  })

  return !isUpgrading && fromPrice.recurring?.interval === 'year'
}
