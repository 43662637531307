import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import Stripe from 'stripe'
import { getQueryKey, queryFunctions } from '~/services'

/**
 * @deprecated Use useBillingSubscriptions instead.
 */
export function useBillingSubscription(workspaceId: string) {
  const { data, ...rest } = useQuery({
    enabled: Boolean(workspaceId),
    queryKey: getQueryKey('workspaces_/$workspaceId_/subscription', { workspaceId }),
    queryFn: async () => queryFunctions['workspaces_/$workspaceId_/subscription']({ workspaceId }),
  })

  const subscription: Stripe.Subscription | undefined =
    data && !_.isEmpty(data) ? (data as Stripe.Subscription) : undefined

  return { subscription, ...rest }
}
