import { Card, Button, showConfirmationPrompt } from '@bpinternal/ui-kit'
import { Webchat, Fab } from '@botpress/webchat'
import { Badge, Flex, Section, Text } from '@radix-ui/themes'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { useEffect, useMemo } from 'react'
import { useAsync } from 'react-use'
import { CodeSnippet, EmptyState as LegacyEmptyState } from '~/components'
import { Boundary, Page } from '~/componentsV2'
import { BpSpinner, EmptyState, Spinner } from '~/elementsv2'
import { getIntegrationsFromBot } from '~/features/bots/services'
import { hasUnmigratedConfigs } from '~/features/webchat/schemas'
import { migrateConfigs } from '~/features/webchat/utils'
import { useCurrentRouteId, useIntegration, useIsAuthorized, useUpdateBot, useWebchat } from '~/hooks'
import { SidebarLayout } from '~/layouts'
import { useSuspenseQuery } from '~/services'
import { getCdmStudioUrl } from '~/shared/configurations'
import { useWebchatStore } from '~/stores'
import { titleCase, isBase64 } from '~/utils'
import semver from 'semver'
import { useWebchatEmbedScript } from '~/hooks/webchat/useWebchatEmbedScript'
import { getUseConfigStore } from '../../../../../features/webchat/stores/useConfigStore'

const currentRoute = '/workspaces/$workspaceId/bots/$botId/webchat' as const
const LATEST_WEBCHAT_VERSION = '0.3.0'

export const Route = createFileRoute(currentRoute)({
  component: () => <Component />,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { user } = Route.useRouteContext()
  const latestWebchatIntegration = useIntegration({ name: 'webchat', version: 'latest' }).data
  const { config, updateWebchatIntegrationAsync } = useWebchat({ botId, workspaceId })
  const isAuthorized = useIsAuthorized({ workspaceId, userId: user.id })
  const { mutate: updateBot, isPending: isPendingUpdateBot } = useUpdateBot()
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data
  const webchatIntegration = Object.values(bot.integrations).find((integration) => integration.name === 'webchat')
  const isVersionWithoutMessaging = semver.gte(webchatIntegration?.version ?? '0.0.0', '0.3.0')
  const isWebchatV2 = useCurrentRouteId()?.includes('/v2/')
  const useConfigStore = useMemo(() => getUseConfigStore({ botId, workspaceId }), [])
  const _hasHydrated = useConfigStore((state) => state._hasHydrated)

  const { embedScript } = useWebchatEmbedScript({ botId, workspaceId })

  const shouldUseWebchatV2 = isWebchatV2 || isVersionWithoutMessaging

  const setTheme = useWebchatStore((state) => state.setTheme)

  const sidebarMenu = ['general', 'theme', 'share', 'advanced-settings'] as const
  const location = useCurrentRouteId() ?? ''

  const items = sidebarMenu.map((item) => ({
    children: titleCase(item),
    to: `/workspaces/$workspaceId/bots/$botId/webchat/${shouldUseWebchatV2 ? 'v2' : 'v1'}/${item}`,
    trailingItem:
      item === 'theme' ? (
        <Badge color="green" variant="outline">
          New
        </Badge>
      ) : undefined,
    active: location.includes(item),
  }))

  useEffect(() => {
    // TODO: Fix this any cast
    setTheme({
      ...config,
      fontFamily: config.fontFamily as any,
      additionalStylesheet: config.additionalStylesheetUrlContent,
    })
  }, [JSON.stringify(config)])

  useAsync(async () => {
    if (hasUnmigratedConfigs(config) && shouldUseWebchatV2 && isAuthorized('bots.update')) {
      await updateWebchatIntegrationAsync({ ...migrateConfigs(config), showToast: false })
    }

    // This is to fix base64 avatar issue
    if (
      (isAuthorized('bots.update') && config.botAvatarUrl && isBase64(config.botAvatarUrl)) ||
      (config.avatarUrl && isBase64(config.avatarUrl))
    ) {
      await updateWebchatIntegrationAsync({ ...config, showToast: false })
    }
  }, [])

  if (!webchatIntegration) {
    return (
      <Page title={'Webchat'}>
        <Section size={'4'}>
          <EmptyState
            title="Webchat not installed"
            description="You must install the Webchat integration to interact with it."
            primaryAction={
              <Button
                onClick={() =>
                  updateBot({
                    id: bot.id,
                    workspaceId,
                    integrations: { [latestWebchatIntegration?.id ?? '']: { enabled: true, configuration: {} } },
                  })
                }
              >
                Install Webchat
              </Button>
            }
          />
        </Section>
      </Page>
    )
  }

  return (
    <>
      <Page
        title={
          <Flex gap={'2'} align={'center'}>
            <Text>Webchat</Text>
            {latestWebchatIntegration?.version !== webchatIntegration?.version && (
              <Button
                size={'1'}
                loading={isPendingUpdateBot}
                onClick={async () => {
                  const result = await showConfirmationPrompt(
                    <Flex direction={'column'} gap={'2'}>
                      <Text weight={'medium'}>Are you sure you want to upgrade? This operation cannot be undone.</Text>
                      <Flex direction={'column'} gap={'2'}>
                        <Text size={'1'} color="gray">
                          Please copy and paste the following code on your webpage before upgrading:
                        </Text>
                        <CodeSnippet code={embedScript ?? ''} />
                        <Text size={'1'} color="red">
                          Webchat version 1 is not supported in the latest version of the integration.
                        </Text>
                      </Flex>
                    </Flex>,
                    { title: 'Upgrade Webchat', showCancel: true, confirmLabel: 'Upgrade', size: '4' }
                  )

                  if (result) {
                    updateBot({
                      id: bot.id,
                      workspaceId,
                      integrations: { [latestWebchatIntegration?.id ?? '']: { enabled: true } },
                    })
                  }
                }}
              >
                Upgrade
              </Button>
            )}
          </Flex>
        }
        navbarItems={items}
      >
        {_hasHydrated ? (
          <SidebarLayout
            main={<Outlet />}
            rightSidebarSize="5"
            rightSidebar={
              <Boundary>
                <RightSidebar />
              </Boundary>
            }
          />
        ) : (
          <div className="mx-auto size-20 h-96">
            <BpSpinner />
          </div>
        )}
      </Page>
    </>
  )
}

const RightSidebar = () => {
  const { workspaceId, botId } = Route.useParams()
  const { user } = Route.useRouteContext()
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data

  const { data: script } = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
    botId,
    workspaceId,
    type: 'fullscreen',
  })

  const { mutate: updateBot } = useUpdateBot()

  const webchatInstalledIntegration = Object.values(bot.integrations).find(
    (integration) => integration.name === 'webchat'
  )

  const isAuthorized = useIsAuthorized({ workspaceId, userId: user.id })
  const isAuthorizedToOpenStudio = isAuthorized('studio.view') || isAuthorized('studio.edit')

  const webchatIntegration = getIntegrationsFromBot(bot).find((integration) => integration.name === 'webchat')
  const isLatestWebchatVersion = semver.gte(webchatIntegration?.version ?? '0.0.0', LATEST_WEBCHAT_VERSION)
  const useConfigStore = useMemo(() => getUseConfigStore({ botId, workspaceId }), [])

  const botName = useConfigStore((state) => state.botName)
  const botAvatar = useConfigStore((state) => state.botAvatar)
  const botDescription = useConfigStore((state) => state.botDescription)
  const composerPlaceholder = useConfigStore((state) => state.composerPlaceholder)
  const showPoweredBy = useConfigStore((state) => state.showPoweredBy)
  const color = useConfigStore((state) => state.color)
  const fontFamily = useConfigStore((state) => state.fontFamily)
  const variant = useConfigStore((state) => state.variant)
  const allowFileUpload = useConfigStore((state) => state.allowFileUpload)
  const email = useConfigStore((state) => state.email)
  const phone = useConfigStore((state) => state.phone)
  const privacyPolicy = useConfigStore((state) => state.privacyPolicy)
  const termsOfService = useConfigStore((state) => state.termsOfService)
  const website = useConfigStore((state) => state.website)
  const themeMode = useConfigStore((state) => state.themeMode)
  const radius = useConfigStore((state) => state.radius)
  const customStyleSheet = useConfigStore((state) => state.customStyleSheet)

  if (!bot.deployedAt || !webchatIntegration) {
    return (
      <Card className="flex h-200 items-center px-8">
        <EmptyState
          iconSize={3}
          title={'Chatbot not published'}
          description={'You must publish the chatbot from Botpress Studio to be able to interact with it.'}
          primaryAction={
            isAuthorizedToOpenStudio ? (
              <a href={getCdmStudioUrl(bot.id)} target="_blank" rel="noopener noreferrer">
                <Button disabled={!isAuthorizedToOpenStudio}>Edit in studio</Button>
              </a>
            ) : undefined
          }
        />
      </Card>
    )
  }

  if (!isLatestWebchatVersion) {
    return (
      <Card className="flex h-200 items-center px-8">
        <EmptyState
          iconSize={3}
          title={'Webchat integration outdated'}
          description={'You must upgrade the webchat integration to interact with it in the Dashboard.'}
        />
      </Card>
    )
  }

  function enableWebchat(webchatIntegrationId: string) {
    updateBot({
      id: bot.id,
      workspaceId,
      integrations: { [webchatIntegrationId]: { enabled: true, configuration: {} } },
    })
  }

  if (!(script && webchatIntegration)) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (!webchatIntegration?.enabled) {
    // TODO: Fix this emptyState
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <LegacyEmptyState
          icon="NoMessages"
          title="Webchat disabled"
          message="You must enable the Webchat integration to interact with it."
        />
        <div className="flex flex-1">
          <div>
            <Button onClick={() => enableWebchat(webchatIntegration.id)}>Enable Webchat</Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Flex direction={'column'} gap={'4'} className="h-200">
      <Webchat
        configuration={{
          botName,
          botAvatar,
          botDescription,
          color,
          fontFamily,
          composerPlaceholder,
          email,
          phone,
          website,
          privacyPolicy,
          termsOfService,
          variant,
          showPoweredBy,
          themeMode,
          radius,
        }}
        allowFileUpload={allowFileUpload}
        apiUrl="https://webchat.botpress.dev" // TODO: Fix this hardcoded value
        clientId={webchatInstalledIntegration?.webhookId ?? ''}
      />
      <div className="ml-auto">
        <Fab />
      </div>
      <style>{customStyleSheet}</style>
    </Flex>
  )
}
