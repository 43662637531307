import Stripe from 'stripe'
import { config, httpClient } from '../../../shared'
import { ProductWithPrices } from '../../usage/useBilling'
import { Invoice } from '../types'
import { PromoCode } from './PromoCode'
import { BillingInfo } from './BillingInfo'

const CDM_BACKEND_URL = config.cdmBackendBaseUrl

export class BillingApiClient {
  static async getInvoices(workspaceId: string): Promise<Invoice[]> {
    return await httpClient.callApi<Invoice[]>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/invoices`,
      method: 'GET',
    })
  }

  static async getSubscription(workspaceId: string) {
    return await httpClient.callApi<Stripe.Subscription | {}>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/subscription`,
      method: 'GET',
    })
  }

  static async getSubscriptions(workspaceId: string) {
    return await httpClient.callApi<Stripe.Subscription[] | {}>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/subscriptions`,
      method: 'GET',
    })
  }

  static async getSubscriptionSchedule(workspaceId: string) {
    return await httpClient.callApi<Stripe.SubscriptionSchedule | {}>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/subscription-schedule`,
      method: 'GET',
    })
  }

  static async getBillingInfo(workspaceId: string) {
    return await httpClient.callApi<BillingInfo | {}>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/info`,
      method: 'GET',
    })
  }

  static async updateSubscription(workspaceId: string, payload: any) {
    return await httpClient.callApi<any>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/subscription`,
      data: payload,
      method: 'PUT',
    })
  }

  static async generateCheckoutSession(workspaceId: string, productQuantityConfig: Record<string, any>, email: string) {
    return await httpClient.callApi<{ url: string }>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/checkout`,
      data: { productQuantityConfig, email },
      method: 'POST',
    })
  }

  static async generateCustomerPortalSession(workspaceId: string) {
    return await httpClient.callApi<{ url: string }>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/customer-portal`,
      method: 'POST',
    })
  }

  static async getBillingProducts(workspaceId: string) {
    return await httpClient.callApi<ProductWithPrices[]>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/products`,
      method: 'GET',
    })
  }

  static async getPromoCode(workspaceId: string) {
    return await httpClient.callApi<{ code: string; description: string } | {}>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/promo-code`,
      method: 'GET',
    })
  }

  static async searchPromoCode({ query, workspaceId }: { query: string; workspaceId: string }) {
    return await httpClient.callApi<PromoCode | {}>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/promo-code/search`,
      method: 'GET',
      params: { code: query },
    })
  }

  static async setPromoCode({ code, workspaceId }: { code: string; workspaceId: string }) {
    return await httpClient.callApi({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/promo-code`,
      method: 'POST',
      data: { code },
    })
  }
}
