import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Navigate } from '@tanstack/react-router'
import { Logs } from '~/components'
import { botQueryOptions } from '~/queries'
import { Page } from '~/componentsV2'
import { z } from 'zod'
import { isoDateRegex } from '@bpinternal/shared'

const today = new Date().toISOString()
const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()

const LogsSearchOptions = z.object({
  startDate: z
    .string()
    .regex(isoDateRegex, 'Invalid ISO date')
    .optional()
    .catch(() => yesterday),
  endDate: z
    .string()
    .regex(isoDateRegex, 'Invalid ISO date')
    .optional()
    .catch(() => today),
  conversationId: z
    .string()
    .min(1)
    .optional()
    .catch(() => undefined),
  userId: z
    .string()
    .min(1)
    .optional()
    .catch(() => undefined),
  level: z
    .union([z.literal('INFO'), z.literal('ERROR'), z.literal('WARN'), z.literal('DEBUG')])
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/logs')({
  component: Component,
  validateSearch: LogsSearchOptions,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data
  const { conversationId, level, userId, endDate, startDate } = Route.useSearch()
  const navigate = Route.useNavigate()

  if (!startDate || !endDate) {
    return (
      <Navigate
        from={Route.fullPath}
        to={Route.fullPath}
        replace
        search={(prev) => ({ ...prev, startDate: startDate || yesterday, endDate: endDate || today })}
      />
    )
  }

  return (
    // TODO: Fix this heigh hack once we remove the old logs from the cdm and we can change the logs component layout
    <Page title="Logs">
      <div className="relative h-[calc(100vh-12rem)]">
        <Logs
          id={bot.id}
          type="bot"
          workspaceId={workspaceId}
          startDate={new Date(startDate)}
          setStartDate={(d: Date) =>
            navigate({
              from: Route.fullPath,
              to: Route.fullPath,
              search: (prev) => ({ ...prev, startDate: d.toISOString() }),
            })
          }
          endDate={new Date(endDate)}
          setEndDate={(d: Date) =>
            navigate({
              from: Route.fullPath,
              to: Route.fullPath,
              search: (prev) => ({ ...prev, endDate: d.toISOString() }),
            })
          }
          conversationId={conversationId}
          level={level}
          userId={userId}
        />
      </div>
    </Page>
  )
}
