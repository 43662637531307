import { useMemo } from 'react'
import { useBilling } from '~/features/usage/useBillingLegacy'
import { useMutation } from '@tanstack/react-query'
import { showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'
import { getQueryKey } from '~/services'
import { isEqual } from 'lodash'
import { queryClient } from '~/providers/ReactQuery'
import { computeTotalCostLegacy } from '~/features/billing/utils'
import { BillingApiClient } from '~/features/billing/services'

type useCheckoutBillingProps = Pick<
  ReturnType<typeof useBilling>,
  | 'activePlanId'
  | 'hasActiveSubscription'
  | 'initialActivePlanId'
  | 'productQuantityMap'
  | 'initialProductQuantityMap'
  | 'subscription'
  | 'generateCheckoutSession'
  | 'promoCode'
>

/**
 *
 * @deprecated use useMonthlyCheckout or useYearlyCheckout instead. This is a legacy hook that is being used for gradual rollout of yearly billing
 */
export function useCheckout(
  workspaceId: string,
  billingProps: useCheckoutBillingProps,
  onUpdateSubscription?: () => void
) {
  const {
    activePlanId,
    hasActiveSubscription,
    initialActivePlanId,
    productQuantityMap,
    initialProductQuantityMap,
    subscription,
    generateCheckoutSession,
    promoCode,
  } = billingProps

  const totalPrice = useMemo(
    () => computeTotalCostLegacy(productQuantityMap, promoCode),
    [productQuantityMap, activePlanId, promoCode]
  )
  const initialTotalPrice = useMemo(
    () => computeTotalCostLegacy(productQuantityMap),
    [initialProductQuantityMap, initialActivePlanId]
  )

  const subscriptionConfigHasChanged = useMemo(() => {
    return initialActivePlanId !== activePlanId || !isEqual(productQuantityMap, initialProductQuantityMap)
  }, [productQuantityMap, initialProductQuantityMap, activePlanId, initialActivePlanId])

  return useMutation({
    gcTime: 0,
    mutationFn: async () => {
      if (!activePlanId || (hasActiveSubscription && !subscriptionConfigHasChanged)) {
        return null
      }

      if (!subscription) {
        const { url } = await generateCheckoutSession()
        await queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_/$workspaceId_', { workspaceId }) })
        window.location.href = url
        return
      }

      const payload = Object.entries(productQuantityMap)
        .filter(([_, product]) => product?.subscriptionLineItemId || product?.quantity)
        .map(([_, product]) => ({
          price: product?.priceId,
          quantity: product?.quantity ?? 0,
        }))

      await BillingApiClient.updateSubscription(workspaceId, {
        productQuantityConfig: payload,
        isUpgrading: initialTotalPrice <= totalPrice,
        subscriptionId: subscription.id,
      })
    },
    onSuccess: () => {
      showSuccessToast('Your subscription has been updated successfully.')
      void queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_/$workspaceId_', { workspaceId }) })
      onUpdateSubscription?.()
    },
    onError: () => {
      showErrorToast('There was an error while updating your subscription. Please try again.')
    },
    meta: {
      suppressError: true,
    },
  })
}
