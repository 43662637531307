import { Badge, Flex, Grid, Link, Separator, Text, TextField, Tooltip } from '@radix-ui/themes'
import { createFileRoute, Link as RouterLink, Navigate } from '@tanstack/react-router'
import { useState } from 'react'
import { Spinner, PageSection, Icon } from '~/elementsv2'
import Stripe from 'stripe'

import {
  type BillingVersion,
  Plan,
  ProductWithPrices,
  TEAM_PLAN_PRODUCT_ID,
  PLUS_PLAN_PRODUCT_ID,
  useBilling,
  ProductQuantityMap,
} from '~/features/usage/useBilling'
import { nanoToBase } from '~/utils'
import { BillingReadonlyCallout, ProductIcon } from '~/features/billing/componentsV2'
import {
  useChangeAiSpendQuota,
  useMonthlyCheckout,
  useCustomerPortal,
  useQueryKeys,
  useUsage,
  useFeature,
} from '~/hooks'
import { formatPhases, formatSubItems } from '~/features/billing/helpers'
import { useQuery } from '@tanstack/react-query'
import { upcomingInvoiceQueryOptions } from '~/queries/billing'
import { trackEvent } from '~/providers'
import { AnalyticEvent } from '~/providers/SegmentProvider'
import { useSearchPromoCode } from '~/hooks/billing/usePromoCodeSearch'
import { useSetPromoCode } from '~/hooks/billing/useSetPromoCode'
import type { PromoCode } from '~/features/billing/services'
import { getPlanColor } from '~/utils/getPlanColor'
import { Card, Button, showConfirmationPrompt, ThemeColor } from '@bpinternal/ui-kit'
import { ArrowRight } from 'lucide-react'
import { SummaryLegacy } from '~/features/billing/componentsV2/BillingLegacy/summary-legacy'

export const Route = createFileRoute('/workspaces/$workspaceId/billing/summary')({
  component: () => {
    const isYearlyBillingEnabled = useFeature('billing-yearly-plans')
    const { workspaceId } = Route.useParams()

    if (!isYearlyBillingEnabled) {
      return <SummaryLegacy workspaceId={workspaceId} />
    } else {
      return <Component />
    }
  },
})

type BillingProps = ReturnType<typeof useBilling>

const isProductPlan = (productId: string) => productId === TEAM_PLAN_PRODUCT_ID || productId === PLUS_PLAN_PRODUCT_ID

function Component() {
  const { workspaceId } = Route.useParams()
  const billingProps = useBilling(workspaceId)
  const billingReadonly = billingProps.billingInfo?.billingReadonly
  //TODO: This spinner has been done the lazy way, it should be replaced with a skeleton loader
  return billingProps.isLoading ? (
    <Flex width={'100%'} align={'center'} justify={'center'} height={'9'}>
      <Spinner />
    </Flex>
  ) : (
    <Flex direction={'column'} gap={'8'}>
      {billingReadonly && <BillingReadonlyCallout />}
      <BillingSummarySection billingReadonly={billingReadonly} {...billingProps} />
      <SubscriptionSection
        billingVersion={billingProps.billingVersion}
        subscriptions={billingProps.subscriptions}
        subscriptionSchedule={billingProps.subscriptionSchedule}
        products={billingProps.products ?? []}
      />
    </Flex>
  )
}

// Backend currently throws an error when trying to fetch the billing information and the customer does not have a subscription, so we need to handle this case in the frontend
const BillingSummarySection = ({
  hasActiveSubscription,
  plan,
  products,
  promoCode,
  billingReadonly,
  ...rest
}: BillingProps & { plan: Plan; billingReadonly?: boolean }) => {
  const { workspaceId } = Route.useParams()
  const { ai_spend } = useUsage({
    workspaceId,
    quotas: ['ai_spend'],
    period: new Date().toISOString().split('T')[0] ?? '',
  })
  const { data } = useQuery(upcomingInvoiceQueryOptions(workspaceId))
  const { mutate: updateAiSpendQuota, isPending: isUpdatingAiSpendQuota } = useChangeAiSpendQuota()
  const { mutate: openCustomerPortal } = useCustomerPortal(workspaceId)
  const { mutate: checkout, isPending: isCheckingOut } = useMonthlyCheckout(workspaceId, {
    hasActiveSubscription,
    promoCode,
    ...rest,
  })

  const [newAiSpendQuota, setNewAiSpendQuota] = useState<number | null>(null)

  function handleUpdateAiSpendQuota() {
    const baseEvent: AnalyticEvent = {
      type: 'update_ai_spend',
      workspaceId,
      newAiSpend: newAiSpendQuota ?? 0,
      prevAiSpend: nanoToBase(ai_spend.quota),
      plan,
      state: 'clicked',
    } as const
    trackEvent({ ...baseEvent, state: 'clicked' })
    if (hasActiveSubscription) {
      return showConfirmationPrompt(
        <Text>
          Are you sure you want to update your AI Spend Limit to{' '}
          <Text weight={'bold'}>${Number(newAiSpendQuota ?? 0)}</Text>?
        </Text>,
        {
          title: 'Update AI Spend Limit',
          confirmLabel: 'Update',
        }
      ).then(() => {
        trackEvent({ ...baseEvent, state: 'confirmed' })
        updateAiSpendQuota({
          workspaceId,
          monthlySpendingLimit: newAiSpendQuota ?? undefined,
          onSuccess: () => setNewAiSpendQuota(null),
        })
      })
    }

    trackEvent({ ...baseEvent, state: 'credit_card_required' })
    return showConfirmationPrompt('Please add a credit card to increase your AI Spend limit.', {
      confirmLabel: 'Subscribe',
      title: 'Credit card required',
    }).then(() => {
      trackEvent({ ...baseEvent, state: 'clicked_add_card' })
      checkout()
    })
  }

  return (
    <Card className="p-4 @container">
      <Grid className="grid-cols-1 @lg:grid-cols-[1fr,auto,1fr,auto,1fr]" gap={'6'}>
        <Flex direction={'column'} gap={'3'}>
          <Text size={'2'}>Your next payment</Text>
          <Text size={'5'} weight={'medium'}>
            ${((data?.total ?? 0) / 100).toFixed(2)}
          </Text>
          <Text size={'1'}>
            <Text color="gray">This amount includes the current spend on metered </Text>
            <Link
              href="https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them"
              target="_blank"
              rel="noreferrer"
              color={ThemeColor}
            >
              AI usage.
            </Link>
          </Text>
        </Flex>
        <Separator className="@lg:h-full @lg:w-px" size="4" />
        <Flex direction={'column'} gap={'3'}>
          <Text size={'2'}>AI Spend Limit</Text>
          <Text size={'5'} weight={'medium'}>
            ${nanoToBase(ai_spend.quota).toFixed(2)}
          </Text>
          <Flex gap={'1'}>
            <TextField.Root
              className="grow"
              disabled={isUpdatingAiSpendQuota || isCheckingOut || !hasActiveSubscription}
              value={newAiSpendQuota ?? ''}
              onChange={(e) => setNewAiSpendQuota(e.target.value ? Number(e.target.value) : null)}
              type="number"
              size={'1'}
              placeholder="10.00"
            >
              <TextField.Slot>
                <Text size={'1'}>$</Text>
              </TextField.Slot>
            </TextField.Root>
            <Button
              size={'1'}
              disabled={(!newAiSpendQuota && hasActiveSubscription) || billingReadonly}
              loading={isUpdatingAiSpendQuota || isCheckingOut}
              onClick={() => {
                void handleUpdateAiSpendQuota()
              }}
              className="grid place-content-center"
            >
              <Text>{hasActiveSubscription ? 'Update' : 'Subscribe'}</Text>
            </Button>
          </Flex>
          <Text size={'1'}>
            <Text color="gray">Maximum monthly limit for bot actions that use AI. </Text>
            <Link color={ThemeColor} target="_blank" href="http://botpress.com/pricing" rel="noreferrer">
              Learn more
            </Link>
          </Text>
        </Flex>
        <Separator className="@lg:h-full @lg:w-px" size="4" />
        <Flex direction={'column'} gap={'4'}>
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'}>Payment information</Text>
            <Link onClick={() => openCustomerPortal()} className="hover:cursor-pointer hover:underline" size={'1'}>
              View invoices and payment information
            </Link>
          </Flex>
          {hasActiveSubscription && (
            <PromoCodeCard
              products={products}
              promoCode={promoCode}
              workspaceId={workspaceId}
              isCheckingOut={isCheckingOut}
            />
          )}
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'}>Need help with billing?</Text>
            <Text size={'1'}>
              <span>Email: </span>
              <Link href="mailto: finance@botpress.com" className="hover:cursor-pointer hover:underline" size={'1'}>
                finance@botpress.com
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Grid>
    </Card>
  )
}

const PromoCodeCard = ({
  workspaceId,
  promoCode,
  isCheckingOut,
  products = [],
  billingReadonly,
}: {
  workspaceId: string
  promoCode: PromoCode | null
  isCheckingOut: boolean
  products?: ProductWithPrices[]
  billingReadonly?: boolean
}) => {
  const [newPromoCode, setNewPromoCode] = useState<string | null>(null)
  const { promoCodeResult, invalid } = useSearchPromoCode({
    workspaceId,
    query: newPromoCode ?? '',
  })
  const { mutate: updatePromoCode, isPending: isUpdatingPromoCode } = useSetPromoCode({ workspaceId })

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const computeDescription = (promoCode: PromoCode) => {
    const {
      coupon: { durationInMonths, percentOff, appliesTo },
    } = promoCode
    const months = durationInMonths === 1 ? 'month' : 'months'
    let description = `${Math.trunc(percentOff)}% off for ${durationInMonths} ${months}`

    if (appliesTo.length > 0) {
      const appliedProducts = products.filter((p) => appliesTo.includes(p.id))
      const productNames = appliedProducts.map((p) => p.name).join(', ')
      description += ` on ${productNames}`
    }

    return description
  }

  return (
    <div>
      {promoCode && (
        <Flex gap={'1'} direction={'column'}>
          <Text size={'2'}>Current promotion</Text>
          <Text size={'1'}>{computeDescription(promoCode)}</Text>
        </Flex>
      )}
      {!promoCode && (
        <div>
          <Text size={'2'}>Add Promo Code</Text>
          <Flex gap={'1'}>
            <TextField.Root
              className="grow"
              disabled={isUpdatingPromoCode || isCheckingOut}
              value={newPromoCode ?? ''}
              onChange={(e) => setNewPromoCode(e.target.value ?? null)}
              type="text"
              size={'1'}
            />
            <Button
              size={'1'}
              disabled={!promoCodeResult || invalid || billingReadonly}
              loading={isUpdatingPromoCode || isCheckingOut}
              onClick={() => {
                if (!newPromoCode) {
                  return
                }

                updatePromoCode({ code: newPromoCode })
              }}
              className="grid place-content-center"
            >
              <Text>Add</Text>
            </Button>
          </Flex>
          <Text size={'1'} color={invalid && newPromoCode ? 'red' : undefined}>
            {promoCodeResult
              ? computeDescription(promoCodeResult)
              : invalid && newPromoCode
                ? 'This code is invalid.'
                : null}
          </Text>
        </div>
      )}
    </div>
  )
}

const SubscriptionItem = ({
  productId,
  quotaName,
  quantity,
  productName,
}: {
  productId: string
  quotaName?: string
  quantity: number
  productName: string
}) => {
  const iconName = quotaName
    ? quotaName
    : isProductPlan(productId) && productId === TEAM_PLAN_PRODUCT_ID
      ? 'team'
      : 'plus'

  const iconColor = isProductPlan(productId) ? getPlanColor(iconName as any) : undefined

  return (
    <Grid gap={'3'} align={'center'} columns={'3'} className="w-fit grid-cols-[auto,1fr,auto]">
      <Icon
        icon={(props) => <ProductIcon quota={iconName as any} {...props} />}
        variant="soft"
        size="2"
        color={iconColor}
      />
      <Text size={'2'} weight={'medium'} className="min-w-[200px] truncate">
        {productName}
      </Text>
      <Text size={'1'} color="gray" weight={'medium'}>
        x {quantity}
      </Text>
    </Grid>
  )
}

const SubscriptionCard = ({
  subscription,
  products,
  subscriptionSchedule,
}: {
  subscription: Stripe.Subscription
  products: ProductWithPrices[]
  subscriptionSchedule?: Stripe.SubscriptionSchedule
}) => {
  const isMonthlySub = subscription.items.data.every((item) => item.plan.interval === 'month')

  const hasPhases = (subscriptionSchedule?.phases?.length ?? 0) > 0

  return (
    <PageSection className="@container" title={`${isMonthlySub ? 'Monthly' : 'Yearly'} subscription`}>
      <div>
        {isMonthlySub && hasPhases ? (
          <Flex gap={'8'} direction={'column'}>
            {formatPhases(subscriptionSchedule?.phases ?? [], products).map((phase) => (
              <Flex gap={'4'} direction={'column'} key={phase.start_date}>
                <Text size={'1'} color="gray" weight={'medium'}>
                  <Flex gap={'2'}>
                    <Text>{phase.start_date}</Text>
                    <Icon icon={ArrowRight} size="1" className="[&>svg]:stroke-[1.5px]" />
                    <Text>{phase.end_date}</Text>
                  </Flex>
                </Text>
                {phase.items.map((item) => (
                  <SubscriptionItem
                    key={item.productId}
                    productId={item.productId}
                    quotaName={item.quotaName}
                    quantity={item.quantity}
                    productName={item.name}
                  />
                ))}
              </Flex>
            ))}
          </Flex>
        ) : (
          <Flex gap={'4'} direction={'column'}>
            {formatSubItems(subscription.items.data, products).map((subItem) => (
              <SubscriptionItem
                key={subItem.productId}
                productId={subItem.productId}
                quotaName={subItem.quotaName}
                quantity={subItem.quantity}
                productName={subItem.name}
              />
            ))}
          </Flex>
        )}
      </div>
    </PageSection>
  )
}
const SubscriptionSection = ({
  billingVersion,
  subscriptions,
  products,
  subscriptionSchedule,
}: {
  billingVersion: BillingVersion
  subscriptions?: Stripe.Subscription[]
  products: ProductWithPrices[]
  subscriptionSchedule?: Stripe.SubscriptionSchedule
}) => {
  //Filter out monthly susbcriptions that only have web search and ai tokens products
  const subscriptionsWithProducts =
    subscriptions?.filter((sub) =>
      sub.items.data.every((item) => item.price.recurring?.interval === 'month') ? sub.items.data.length > 2 : true
    ) ?? []

  const isLegacyPricing = billingVersion === 'v1' || billingVersion === 'v2'

  return (
    <>
      {!subscriptionsWithProducts?.length && (
        <Text size={'3'} align={'center'} className="p-4">
          You don&apos;t have any active subscriptions
        </Text>
      )}
      {subscriptionsWithProducts.map((sub) => (
        <SubscriptionCard
          key={sub.id}
          subscription={sub}
          products={products}
          subscriptionSchedule={subscriptionSchedule}
        />
      ))}
      {isLegacyPricing && (
        <Text size={'2'} weight={'medium'} color="gray" className="pl-2">
          * This workspace is subject to legacy pricing and may not reflect prices listed on the Botpress website.
          Please refer to{' '}
          {
            <Link
              underline="always"
              color={ThemeColor}
              href="https://botpress.com/pricing#price-faq"
              target="_blank"
              rel="noreferrer"
            >
              this page
            </Link>
          }{' '}
          for more information
        </Text>
      )}
    </>
  )
}
