import { Badge, Flex, Grid, Link as ThemeLink, Separator, Text } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { SidebarLayout } from '~/layouts'
import { ComponentProps, Fragment, ReactNode, forwardRef, useState } from 'react'
import { useDetailedUsage, useUsage } from '~/hooks'
import { EmptyState, Link, Popover } from '~/elementsv2'
import { BarList, DonutChart } from '@tremor/react'
import { QuotaType } from '@bpinternal/const'
import { formatValueWithUnit } from '~/features/usage/helpers'
import { useSuspenseQuery } from '@tanstack/react-query'
import { listBotsQueryOptions } from '~/queries'
import { blue, grass, orange, ruby, yellow, purple } from '@radix-ui/colors'
import { Usage } from 'botpress-client'
import { BotSummary } from '~/features/bots/types'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '~/features/usage/constants'
import { cn } from '~/utils'
import { Boundary, Page, UsageMonthSelector, UsageProgressBar } from '~/componentsV2'
import InvocationEmptyStateIcon from '~/assets/programming-04.svg?react'
import FileEmptyStateIcon from '~/assets/data-analytics-01.svg?react'
import VectorEmptyStateIcon from '~/assets/data-analytics-04.svg?react'
import TableEmptyStateIcon from '~/assets/data-analytics-14.svg?react'
import AiSpendEmptyStateIcon from '~/assets/data-analytics-15.svg?react'
import { HiPlus } from 'react-icons/hi2'
import { Card, Button } from '@bpinternal/ui-kit'

const colors = [blue.blue8, ruby.ruby8, yellow.yellow8, grass.grass8, orange.orange8, purple.purple8]

type DetailedUsage = Usage & {
  breakdown: ({
    botId: string
    value: number
  } & Partial<BotSummary>)[]
}
const valueFormatter = (type: QuotaType) => (value: number) => formatValueWithUnit(value, type)

const getUsageEmptyStateIcon = (type: QuotaType) => {
  switch (type) {
    case 'ai_spend':
      return AiSpendEmptyStateIcon
    case 'storage_count':
      return FileEmptyStateIcon
    case 'table_row_count':
      return TableEmptyStateIcon
    case 'knowledgebase_vector_storage':
      return VectorEmptyStateIcon
    case 'invocation_calls':
      return InvocationEmptyStateIcon
    default:
      return InvocationEmptyStateIcon
  }
}

export const Route = createFileRoute('/workspaces/$workspaceId/usage')({
  component: Component,
})

function Component() {
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())
  return (
    <Page
      title="Usage"
      actions={
        <UsageMonthSelector
          selectedMonth={selectedMonth}
          debouncedOnChange={(date) => setSelectedMonth(date)}
          className="ml-auto"
        />
      }
    >
      <Boundary>
        {/* TODO: Add Skeleton loading screen */}
        <UsagePageContent selectedMonth={selectedMonth} />
      </Boundary>
    </Page>
  )
}

type UsagePageContentProps = {
  selectedMonth: Date
}

const UsagePageContent = ({ selectedMonth }: UsagePageContentProps) => {
  const { workspaceId } = Route.useParams()

  const bots = useSuspenseQuery(listBotsQueryOptions(workspaceId)).data

  const { ai_spend: aiSpendUsage, ...detailedUsage } = useDetailedUsage({
    workspaceId,
    quotas: ['ai_spend', 'storage_count', 'table_row_count', 'knowledgebase_vector_storage', 'invocation_calls'],
    period: selectedMonth.toISOString().split('T')[0] ?? '',
  })

  const basicUsage = useUsage({
    workspaceId,
    quotas: ['workspace_member_count', 'bot_count', 'always_alive'],
    period: selectedMonth.toISOString().split('T')[0] ?? '',
  })

  const getBotProperties = (botId: string) => bots.find((b) => b.id === botId) ?? { name: '[Deleted bot]' }

  return (
    <SidebarLayout
      main={
        <Flex direction={'column'} gap={'4'}>
          <UsageCard
            size="lg"
            usage={{
              ...aiSpendUsage,
              breakdown: aiSpendUsage.breakdown
                .filter((d) => d.value > 0)
                .map(({ botId, value }) => ({
                  botId,
                  value,
                  ...getBotProperties(botId),
                })),
            }}
            type={aiSpendUsage.type}
            description={
              <>
                Track your usage of third-party AI services here. Botpress integrates with OpenAI to process natural
                language for your chatbot, incurring a cost based on the amount of{' '}
                <ThemeLink
                  color="blue"
                  href="https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tokens
                </ThemeLink>{' '}
                consumed. This meter helps you monitor your spend against your budget.
              </>
            }
            className="flex flex-col gap-6"
          >
            <Grid className={cn('col-span-4 grid-cols-subgrid')} mt={'4'} align={'center'} gap={'2'}>
              <Text size={'1'} className="col-span-3 col-start-2">
                <Text weight={'medium'}>{formatValueWithUnit(aiSpendUsage.quota, aiSpendUsage.type)}</Text>
                <Text color="gray"> monthly spending limit | </Text>
                <Link to="/workspaces/$workspaceId/billing" params={{ workspaceId }}>
                  Manage spending limit
                </Link>
              </Text>
            </Grid>
          </UsageCard>
          <Grid gap={'4'} className="grid-cols-1 grid-rows-[auto,1fr] @xl:grid-cols-2">
            {Object.entries(detailedUsage).map(([type, usage]) => (
              <UsageCard
                key={type}
                usage={{
                  ...usage,
                  breakdown: usage.breakdown
                    .filter((d) => d.value > 0)
                    .map(({ botId, value }) => ({
                      botId,
                      value,
                      ...getBotProperties(botId),
                    })),
                }}
                type={type as QuotaType}
              />
            ))}
          </Grid>
        </Flex>
      }
      rightSidebar={
        <Flex direction={'column'} gap={'4'} pt={'4'}>
          <Link to={'/workspaces/$workspaceId/billing'} params={{ workspaceId }} size="2">
            <Button color="grass" leading={<HiPlus />}>
              Increase Limits
            </Button>
          </Link>
          <Flex direction={'column'} gap="4" pr="4">
            {Object.values(basicUsage).map((usage) => (
              <UsageProgressBar
                title={<Text weight={'medium'}>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[usage.type].name}</Text>}
                description={
                  <Text size={'1'} color="gray">
                    {QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[usage.type].description}
                  </Text>
                }
                key={usage.type}
                {...usage}
              />
            ))}
          </Flex>
        </Flex>
      }
    />
  )
}

type UsageCardProps = {
  usage: DetailedUsage
  type: QuotaType
  size?: 'sm' | 'lg'
  description?: ReactNode
} & ComponentProps<typeof Card>
const UsageCard = forwardRef<HTMLDivElement, UsageCardProps>(
  ({ usage, type, size = 'sm', className, description, children, ...props }, ref) => {
    const breakdowns = [...usage.breakdown].sort((a, b) => b.value - a.value)

    const topUsages = breakdowns.slice(0, 3)
    const otherUsages =
      breakdowns.length > 3
        ? [{ value: breakdowns.slice(3).reduce((acc, { value }) => acc + value, 0), name: 'Other' }]
        : []
    const otherUsagesData = breakdowns.slice(3).map(({ value, name, botId }) => ({ value, name: name ?? botId }))

    const remaining = usage.quota > usage.value ? [{ name: 'Remaining', value: usage.quota - usage.value }] : []

    return (
      <Card {...props} ref={ref} className={cn('flex flex-col gap-4 p-5 @container', className)}>
        <Flex direction={'column'}>
          <Text weight={'medium'}>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].name}</Text>
          <Text size={'1'} color="gray">
            {description ? description : QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].description}
          </Text>
        </Flex>
        {breakdowns.length === 0 ? (
          <EmptyState
            gap={'2'}
            icon={getUsageEmptyStateIcon(usage.type)}
            description="No usage data available for this period."
            descriptionSize="1"
            className="grow self-center"
          />
        ) : (
          <Flex
            align={'center'}
            gap={'4'}
            flexGrow={'1'}
            className={cn('flex-col', { '@sm:px-8 @2xl:flex-row': size === 'lg' }, { '@xs:flex-row': size === 'sm' })}
          >
            <DonutChart
              data={[...topUsages, ...otherUsages, ...remaining]}
              showLabel={false}
              valueFormatter={valueFormatter(type)}
              colors={[...colors.slice(0, topUsages.length + otherUsages.length), 'gray-3']}
              index="name"
              category="value"
              className={cn('col-start-1 row-start-2 flex-none', size === 'sm' ? 'h-24 w-24' : 'h-40 w-40')}
            />
            <Flex direction={'column'} width={'100%'} gap={'5'}>
              <Grid
                gap={'1'}
                width={'100%'}
                className={cn('grid-cols-[auto,1fr,auto]', { '@2xl:px-8': size === 'lg' })}
              >
                {topUsages.map(({ name, value }, i) => (
                  <Fragment key={name}>
                    <UsageLegendItem
                      color={colors[i]}
                      label={name}
                      value={formatValueWithUnit(value, type)}
                      badge={`${((value / usage.quota) * 100).toFixed(1)}%`}
                    />
                    <Separator className="col-span-4" size="4" />
                  </Fragment>
                ))}
                {otherUsages.length > 0 && (
                  <>
                    <UsageLegendItem
                      color={colors[topUsages.length]}
                      label={
                        <Popover trigger={<ThemeLink>{otherUsages[0]?.name}</ThemeLink>} className="w-64">
                          <BarList
                            valueFormatter={valueFormatter(usage.type)}
                            data={otherUsagesData}
                            color={blue.blue8}
                          />
                        </Popover>
                      }
                      value={formatValueWithUnit(otherUsages[0]?.value ?? 0, type)}
                      badge={`${((otherUsages.reduce((acc, { value }) => acc + value, 0) / usage.quota) * 100).toFixed(
                        1
                      )}%`}
                    />
                    <Separator className="col-span-4" size="4" />
                  </>
                )}

                <UsageLegendItem
                  mt={'2'}
                  label="Total"
                  value={`${formatValueWithUnit(usage.value, type)} of ${formatValueWithUnit(usage.quota, type)}`}
                  badge={`${((usage.value / usage.quota) * 100).toFixed(1)}%`}
                  className="font-medium"
                />
                {children}
              </Grid>
            </Flex>
          </Flex>
        )}
      </Card>
    )
  }
)
UsageCard.displayName = 'UsageCard'

type UsageLegendItemProps = {
  color?: string
  label?: ReactNode
  value?: string
  badge?: string
} & ComponentProps<typeof Grid>

const UsageLegendItem = ({ color, label, value, badge, className, ...props }: UsageLegendItemProps) => {
  return (
    <Grid {...props} className={cn('col-span-3 grid-cols-subgrid', className)} align={'center'} gap={'2'}>
      <div className={`bg-[${color}] size-2 rounded-sm`} />
      <Flex>
        <Text size={'2'} className="truncate">
          {label}
        </Text>
        <Text size={'2'} color="gray" ml={'auto'} className="ml-auto justify-self-center">
          {value}
        </Text>
      </Flex>
      <Badge color="gray" className="w-min justify-self-center">
        {badge}
      </Badge>
    </Grid>
  )
}
