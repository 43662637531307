import { useSuspenseQuery, type QueryClient } from '@tanstack/react-query'
import { Outlet, useParams, ScrollRestoration, createRootRouteWithContext } from '@tanstack/react-router'
import { useAppStore, useLocalStore } from '../stores'
import { workspacesQueryOptions } from '../queries'
import { TopNav } from '~/layoutsV2/TopNav'
import { Box, Container } from '@radix-ui/themes'
import { useCurrentRouteId } from '../hooks/useCurrentRouteId'
import { AppFooter } from '~/layoutsV2/AppFooter'
import { useEffect } from 'react'
import { trackPage, usePortal } from '../providers'
import { SideNav } from '~/layoutsV2'
import { DialogProvider, ExpandButton, SidebarApp } from '@bpinternal/ui-kit'
import { cn } from '../utils'
import { FileRouteTypes } from '~/routeTree.gen'

const FULL_SCREEN_ROUTES: FileRouteTypes['fullPaths'][] = ['/welcome']

type RouterContext = {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async () => {
    const { currentUser: user } = useAppStore.getState()
    if (!user) {
      throw new Error('Unable to get current user')
    }
    return { user }
  },
  loader: ({ context }) => context.queryClient.ensureQueryData(workspacesQueryOptions()),
  component: RootComponent,
})

function RootComponent() {
  const { data: workspaces } = useSuspenseQuery(workspacesQueryOptions())
  const routeId = useCurrentRouteId()
  const expandedSidebar = useLocalStore((state) => state.expandedSidebar)
  const setExpandedSidebar = useLocalStore((state) => state.setExpandedSidebar)

  const { setBannerPortalRef } = usePortal()

  const { workspaceId, botId, conversationId } = useParams({ strict: false })

  useEffect(() => {
    if (routeId) {
      trackPage({ name: routeId, workspaceId, botId, conversationId })
    }
  }, [routeId])

  const isFullScreen = FULL_SCREEN_ROUTES.includes(routeId ?? '')

  return (
    <>
      <ScrollRestoration />
      <DialogProvider />
      {isFullScreen ? (
        <Box className="min-h-svh w-full">
          <Container>
            <Box className="has-[:not(.hidden)]:mb-4" ref={setBannerPortalRef} />
          </Container>
          <Outlet />
        </Box>
      ) : (
        <SidebarApp
          className="min-h-svh w-full p-4 lg:p-0"
          header={<TopNav className="h-14" workspaces={workspaces} />}
          sidebar={
            <>
              <SideNav expanded={expandedSidebar} className={cn(expandedSidebar && 'w-64')} />
              <ExpandButton
                expanded={expandedSidebar}
                onClick={() => setExpandedSidebar(!expandedSidebar)}
                color="gray"
                className="absolute -right-6 top-1/2 -translate-y-1/2 transform"
              />
            </>
          }
        >
          <Box className="lg:p-8">
            <Container size={'4'}>
              <Box className="has-[:not(.hidden)]:mb-4" ref={setBannerPortalRef} />
            </Container>
            <Outlet />
          </Box>
          {/* TODO: Temporary hack for initial release until we remove dashboard v1 and fix the position on the component */}
          {!(routeId?.includes('issues') || routeId?.includes('logs')) && <AppFooter />}
        </SidebarApp>
      )}
    </>
  )
}
