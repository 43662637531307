import type { BillingVersion, Plan } from '~/features/usage/useBilling'
import { Check } from 'lucide-react'
import { Flex, Text } from '@radix-ui/themes'
import { billingPlan } from '~/features/billing/constants'
import { getPlanColor } from '~/utils/getPlanColor'

type PlanInclusionsProps = {
  plan: Plan
  billingVersion: BillingVersion
}

export const PlanInclusions = ({ plan, billingVersion }: PlanInclusionsProps) => {
  const planColor = getPlanColor(plan)

  return (
    <Flex direction={'column'} gap={'3'} data-accent-color={planColor}>
      {billingPlan[plan].inclusions[billingVersion].map((feature) => (
        <Flex key={feature} align={'start'} gap={'2'}>
          <Check className={`h-5 flex-none stroke-[1.5px] text-accent-9`} />
          <Text size={'2'} color="gray">
            {feature}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}
