import { Plan } from '~/features/usage/useBilling'
import { Color } from '~/types'

export const getPlanColor = (planName: Plan): Color => {
  const planColors: Record<Plan, Color> = {
    team: 'grass',
    enterprise: 'iris',
    plus: 'indigo',
    community: 'blue',
  }

  return planColors[planName]
}
