import { IconButton } from '../IconButton'
import { type ComponentProps } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import {
  iconContainer,
  verticalBar,
  iconButton,
  defaultIcon,
  collapseIcon,
  expandIcon,
} from './ExpandButton.module.css'
import clsx from 'clsx'
import { Tooltip } from '@radix-ui/themes'

type Props = { expanded: boolean; className?: string } & Omit<ComponentProps<typeof IconButton>, 'icon' | 'variant'>

export const ExpandButton = ({ className, expanded, ...props }: Props) => {
  const Icon = () => (
    <>
      <div className={clsx(iconContainer, defaultIcon)}>
        <div className={verticalBar}></div>
      </div>

      <div className={clsx(iconContainer, collapseIcon)}>
        <ChevronLeft />
      </div>

      <div className={clsx(iconContainer, expandIcon)}>
        <ChevronRight />
      </div>
    </>
  )

  return (
    <Tooltip content={expanded ? 'Collapse' : 'Expand'} side="right">
      <IconButton
        {...props}
        data-expanded={expanded}
        variant="ghost"
        className={clsx(className, iconButton)}
        icon={Icon}
      />
    </Tooltip>
  )
}
