import { AudioBlock } from '@botpress/webchat@2.3.7'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage } from './Utils'

export const VoiceSchema = BaseMessage.extend({
  type: z.literal('voice'),
  payload: z.object({
    audioUrl: z.string().min(1),
  }),
})
  .transform(({ payload: { audioUrl }, ...props }) => ({ ...props, block: { type: 'audio' as const, url: audioUrl } }))
  .transform<MessageObject<AudioBlock>>(ToMessage)
