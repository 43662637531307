import { useMutation } from '@tanstack/react-query'
import { showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'
import { getStudioClientForBot } from '../../client'

type Props = {
  sourceBotId: string
  targetBotId: string
  workspaceId: string
  onSettled?: () => void
  onSuccess?: () => void
  onError?: (error: Error) => void
}

export function useCopyToBot() {
  return useMutation({
    mutationFn: async ({ workspaceId, sourceBotId, targetBotId }: Props) => {
      const studioClient = getStudioClientForBot(sourceBotId, workspaceId)
      return await studioClient.post('/copyTo', {
        sourceBotId,
        targetBotId,
      })
    },
    onSuccess: async (_data, { onSuccess }) => {
      showSuccessToast('Bot copied successfully')
      onSuccess?.()
    },
    onError: (error: Error, { onError }) => {
      showErrorToast(`Failed to copy bot, due to error: ${error.message}`)
      onError?.(error)
    },
    onSettled: (_data, _error, { onSettled }) => {
      onSettled?.()
    },
    meta: {
      suppressError: false,
    },
  })
}
