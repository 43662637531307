import { Link, LinkProps } from '@tanstack/react-router'
import { Fragment, type ComponentProps, type FC, type ReactNode } from 'react'
import { NavMenu } from '@bpinternal/ui-kit'
import { match } from 'ts-pattern'

export type NavbarItem =
  | {
      type: 'link'
      text: string
      leadingIcon?: FC<{ className?: string; size?: number | string; strokeWidth?: number }>
      trailingItem?: ReactNode
      to: LinkProps['to']
      active?: boolean
    }
  | {
      type: 'separator'
    }
  | {
      type: 'label'
      text: string
    }
  | {
      type: 'custom'
      content: ReactNode
    }

type Props = {
  workspaceId?: string
  botId?: string
  integrationId?: string
  items: NavbarItem[]
  className?: string
  children?: ReactNode
  expanded?: boolean
} & ComponentProps<typeof NavMenu.Root>

export const Navbar = ({ workspaceId, botId, integrationId, items, expanded = true, children, ...props }: Props) => {
  return (
    <NavMenu.Root {...props}>
      {items.map((item, i) =>
        match(item)
          .with({ type: 'separator' }, () => <NavMenu.Separator key={i} />)
          .with({ type: 'label' }, ({ text }) => <NavMenu.Label key={text}>{text}</NavMenu.Label>)
          .with({ type: 'link' }, ({ text, leadingIcon, trailingItem, to, active }) => (
            <NavMenu.Link key={to}>
              <Link to={to} key={to} params={{ workspaceId, botId, integrationId }}>
                <NavMenu.MenuItem active={active} Icon={leadingIcon} trailing={trailingItem} open={expanded}>
                  {text}
                </NavMenu.MenuItem>
              </Link>
            </NavMenu.Link>
          ))
          .with({ type: 'custom' }, ({ content }) => <Fragment key={i}>{content}</Fragment>)
          .exhaustive()
      )}
      {children}
    </NavMenu.Root>
  )
}
