import { createFileRoute, Navigate } from '@tanstack/react-router'
import { z } from 'zod'
import { WelcomePage } from '~/features/billing/WelcomePage'

export const Route = createFileRoute('/welcome')({
  validateSearch: z.object({
    plan: z
      .enum(['team', 'plus'])
      .optional()
      .catch(() => undefined),
    billingVersion: z
      .enum(['v1', 'v2', 'v3'])
      .optional()
      .catch(() => undefined),
  }),
  component: () => {
    const { billingVersion, plan } = Route.useSearch()

    if (!plan || !billingVersion) {
      return <Navigate to="/" replace />
    } else {
      return <WelcomePage plan={plan} billingVersion={billingVersion} />
    }
  },
})
