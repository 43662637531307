import { DefaultError, InfiniteData, QueryFunction, QueryKey, useInfiniteQuery } from '@tanstack/react-query'
import { getApiClient } from '../client'
import { Log } from '../components/logs/types'
import { cacheDuration } from '../shared'
import { useQueryKeys } from './useQueryKeys'

type QueryFnData = {
  logs: Log[]
  nextToken?: string
}

type PageParam = {
  nextToken?: string
}

export function useLogs({
  workspaceId,
  type,
  id,
  startDate,
  endDate,
  conversationId,
  userId,
  level,
}: {
  workspaceId: string
  type: 'bot' | 'integration'
  id: string
  startDate: Date
  endDate: Date
  conversationId?: string
  userId?: string
  level?: 'INFO' | 'ERROR' | 'WARN' | 'DEBUG'
}) {
  const apiClient = getApiClient(workspaceId)
  const { listLogs } = useQueryKeys()

  const loadLogs: QueryFunction<QueryFnData, QueryKey, PageParam> = async ({ pageParam }) => {
    const { nextToken } = pageParam

    const method = type === 'bot' ? apiClient.getBotLogs : apiClient.getIntegrationLogs

    const { logs, nextToken: newNextToken } = await method({
      id,
      timeStart: toUTC(startDate).toISOString(),
      timeEnd: toUTC(endDate).toISOString(),
      nextToken,
      conversationId,
      userId,
      level,
    })

    return {
      logs: logs.map((log) => ({
        ...log,
        timestamp: log.timestamp,
        tags: {},
      })),
      nextToken: newNextToken,
    }
  }

  return useInfiniteQuery<QueryFnData, DefaultError, InfiniteData<QueryFnData>, QueryKey, PageParam>({
    queryFn: loadLogs,
    queryKey: listLogs({ type, id, startDate, endDate, conversationId, userId, level }),
    staleTime: cacheDuration.medium,
    initialPageParam: {},
    refetchOnWindowFocus: false,
    meta: {
      errorMessage: "Sorry, we couldn't load the recent logs at this time",
    },
    getNextPageParam: () => {
      return null
    },
    getPreviousPageParam: (firstPage, _pages) => {
      if (!firstPage?.nextToken) {
        return
      }

      return {
        nextToken: firstPage.nextToken,
      }
    },
  })
}

const toUTC = (date: Date) =>
  new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  )
