import { ClientOutputs } from 'botpress-client'
import { BillingVersion } from '~/features/usage/useBilling'

type BillingPlanFeatures = {
  name: string
  description: string
  inclusions: Record<BillingVersion, string[]>
  exclusions: string[]
}

//TODO: This should be centralised. We need to keep this in sync with our website and stripe products
export const billingPlan: Record<ClientOutputs['getWorkspace']['plan'], BillingPlanFeatures> = {
  community: {
    name: 'Pay-as-you-go',
    description: 'Start for free. Every month we give you a $5 credit to use towards AI Spend.',
    inclusions: {
      v1: [
        '5 bots',
        '2,000 incoming messages & events / month',
        '3 collaborators',
        '5,000 Table rows',
        '100MB vector database storage',
        '500MB File storage',
        '30 days of data retention',
        '100 API Req/sec',
        'Community support',
        '$5 AI Credit',
      ],
      v2: [
        '5 bots',
        '2,000 incoming messages & events / month',
        '3 collaborators',
        '5,000 Table rows',
        '100MB vector database storage',
        '500MB File storage',
        '30 days of data retention',
        '100 API Req/sec',
        'Community support',
        '$5 AI Credit',
      ],
      v3: [
        '1 bot',
        '500 incoming messages & events / month',
        '1 workspace seat',
        '1,000 Table rows',
        '100MB vector database storage',
        '100MB File storage',
        '30 days of data retention',
        '100 API Req/sec',
        'Community support',
        '$5 AI Credit',
      ],
    },
    exclusions: [
      'Live Agent Handoff',
      'Removing "Powered by Botpress" in WebChat',
      'Role-based access control',
      'Premium Web Crawler',
      'Custom SSO',
      'Multiple Environments',
      'Real-Time Collaboration',
      'Custom Analytics',
    ],
  },

  //Plus does not support v1 and v2 billing versions
  plus: {
    name: 'Plus',
    description: 'A flexible plan ideal for teams scaling their agent projects.',
    inclusions: {
      v1: [],
      v2: [],
      v3: [
        'Live Agent Handoff',
        'Removing "Powered by Botpress" in WebChat',
        '2 bots (1 with Always Alive)',
        '5,000 incoming messages & events / month',
        '2 collaborators',
        '100,000 Table rows',
        '1GB vector database storage',
        '10GB File storage',
        '30 days of data retention',
        '100 API Req/sec',
        'Community support',
        'Custom Analytics',
        '$5 AI Credit',
      ],
    },
    exclusions: [
      'Role-based access control',
      'Premium Web Crawler',
      'Custom SSO',
      'Multiple Environments',
      'Real-Time Collaboration',
      'Live-chat support',
    ],
  },
  team: {
    name: 'Team',
    description: 'For teams with mature chatbot projects looking to save on limit increases.',
    inclusions: {
      v1: [
        'Live Agent Handoff',
        'Removing "Powered by Botpress" in WebChat',
        '20 bots',
        '250,000 incoming messages & events / month',
        '5 collaborators',
        '1,000,000 Table rows',
        '2GB vector database storage',
        '10GB File storage',
        '90 days of data retention',
        '1000 API Req/sec',
        'Live-chat support',
        'Real-Time Collaboration',
        'Custom Analytics',
      ],
      v2: [
        'Live Agent Handoff',
        'Removing "Powered by Botpress" in WebChat',
        '20 bots (with Always Alive)',
        '250,000 incoming messages & events / month',
        '5 collaborators',
        '1,000,000 Table rows',
        '2GB vector database storage',
        '10GB File storage',
        '90 days of data retention',
        '1000 API Req/sec',
        'Live-chat support',
        'Real-Time Collaboration',
        'Custom Analytics',
      ],
      v3: [
        'Live Agent Handoff',
        'Removing "Powered by Botpress" in WebChat',
        '3 bots (with Always Alive)',
        '50,000 incoming messages & events / month',
        '3 collaborators',
        '100,000 Table rows',
        '2GB vector database storage',
        '10GB File storage',
        '90 days of data retention',
        '1000 API Req/sec',
        'Live-chat support',
        'Real-Time Collaboration',
        'Custom Analytics',
      ],
    },
    exclusions: ['Custom SSO', 'Multiple Environments', 'Dedicated support'],
  },
  enterprise: {
    name: 'Enterprise',
    description: 'For businesses requiring advanced features and enterprise security.',
    inclusions: {
      v1: [
        'Custom workspace limits',
        'Volume discounts',
        'Custom payment terms',
        'Custom SLAs & Agreements',
        'Dedicated support',
      ],
      v2: [
        'Custom workspace limits',
        'Volume discounts',
        'Custom payment terms',
        'Custom SLAs & Agreements',
        'Dedicated support',
      ],
      v3: [
        'Custom workspace limits',
        'Volume discounts',
        'Custom payment terms',
        'Custom SLAs & Agreements',
        'Dedicated support',
      ],
    },
    exclusions: [],
  },
}
