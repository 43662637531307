import { VideoBlock } from '@botpress/webchat@2.3.7'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage } from './Utils'

export const VideoSchema = BaseMessage.extend({
  type: z.literal('video'),
  payload: z.object({
    videoUrl: z.string().min(1),
  }),
})
  .transform(({ type, payload: { videoUrl }, ...props }) => ({ ...props, block: { type, url: videoUrl } }))
  .transform<MessageObject<VideoBlock>>(ToMessage)
