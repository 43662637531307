import { IntercomProvider as OriginalIntercomProvider } from 'react-use-intercom'
import { config } from '~/shared/configurations'

import { useAppStore } from '~/stores'

export const INTERCOM_ELEMENT_ID = 'btn-toggle-livechat'

const INTERCOM_APP_ID = config.intercomAppId

type Props = {
  children?: React.ReactNode
}

export const IntercomProvider = ({ children }: Props) => {
  const user = useAppStore((s) => s.currentUser)
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)

  const intercomConfig = {
    userId: user?.id,
    email: user?.email,
    customAttributes: { workspaceId: activeWorkspace?.id },
    alignment: 'right',
    verticalPadding: 50,
    hideDefaultLauncher: true,
    customLauncherSelector: `#${INTERCOM_ELEMENT_ID}`,
  }

  return (
    <OriginalIntercomProvider appId={INTERCOM_APP_ID ?? ''} autoBoot={!!INTERCOM_APP_ID} autoBootProps={intercomConfig}>
      {children}
    </OriginalIntercomProvider>
  )
}
