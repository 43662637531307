import { createFileRoute } from '@tanstack/react-router'
import { Page } from '~/componentsV2'
import { useSuspenseQuery } from '~/services'
import { Table, Text } from '@radix-ui/themes'
import { Icon } from '~/elementsv2'
import { CircleSlash, Lock, LockOpen, Sparkles, TableIcon } from 'lucide-react'
import { formatDate } from '@bpinternal/shared'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/tables/')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const { data: tables } = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/tables/$tags/all', {
    botId,
    workspaceId,
    tags: {},
  })

  const navigate = Route.useNavigate()

  return (
    <Page title="Tables">
      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell width={'20px'} pr={'0'} />
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Columns</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell justify={'center'}>Frozen</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell justify={'center'}>Computed</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Last Updated</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tables?.tables.map(({ frozen, tags, id, name, updatedAt, schema, isComputeEnabled }) => {
            const isSystem = tags?.['system'] === 'true'
            return (
              <Table.Row
                key={id}
                className="cursor-pointer hover:bg-gray-2"
                onClick={() =>
                  navigate({
                    to: '/workspaces/$workspaceId/bots/$botId/tables/$tableId',
                    params: { tableId: id },
                    search: { limit: 50, offset: 0 },
                  })
                }
              >
                <Table.Cell pr={'0'}>
                  <Icon icon={TableIcon} color={isSystem ? 'gray' : 'grass'} variant="soft" />
                </Table.Cell>
                <Table.Cell>
                  <Text>{name}</Text>
                </Table.Cell>
                <Table.Cell>
                  <Text>{Object.keys(schema.properties).length}</Text>
                </Table.Cell>
                <Table.Cell justify={'center'}>
                  <Icon
                    icon={frozen ? Lock : LockOpen}
                    className="mx-auto"
                    color={frozen ? 'blue' : 'gray'}
                    muted={!frozen}
                    padding
                    variant={frozen ? 'soft' : 'outline'}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Icon
                    icon={isComputeEnabled ? Sparkles : CircleSlash}
                    className="mx-auto"
                    color={isComputeEnabled ? 'iris' : 'gray'}
                    muted={!isComputeEnabled}
                    padding
                    variant={isComputeEnabled ? 'soft' : 'outline'}
                  />
                </Table.Cell>
                <Table.Cell>{updatedAt && <Text>{formatDate(updatedAt, { relative: true })}</Text>}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </Page>
  )
}
