import { ComponentProps, FC } from 'react'
import {
  HiOutlineArrowPathRoundedSquare,
  HiOutlineBolt,
  HiOutlineBuildingOffice,
  HiOutlineCircleStack,
  HiOutlineChatBubbleBottomCenterText,
  HiOutlineDocument,
  HiOutlineExclamationTriangle,
  HiOutlineQuestionMarkCircle,
  HiOutlineTableCells,
  HiOutlineUsers,
  HiOutlineLightBulb,
  HiOutlineSquaresPlus,
} from 'react-icons/hi2'
import { QuotaType } from '@bpinternal/const'

type Props = {
  quota?: QuotaType | 'team' | 'plus'
} & ReactIconProps

type ReactIconProps = ComponentProps<typeof HiOutlineArrowPathRoundedSquare>

const icon: Record<QuotaType | 'team' | 'plus', FC<ReactIconProps>> = {
  table_row_count: HiOutlineTableCells,
  knowledgebase_vector_storage: HiOutlineCircleStack,
  storage_count: HiOutlineDocument,
  workspace_member_count: HiOutlineUsers,
  always_alive: HiOutlineBolt,
  invocation_calls: HiOutlineChatBubbleBottomCenterText,
  bot_count: HiOutlineSquaresPlus,
  team: HiOutlineBuildingOffice,
  plus: HiOutlineLightBulb,

  //NOT IMPLEMENTED
  ai_spend: HiOutlineQuestionMarkCircle,
  bing_search_spend: HiOutlineQuestionMarkCircle,
  integrations_owned_count: HiOutlineQuestionMarkCircle,
  invocation_timeout: HiOutlineQuestionMarkCircle,
  openai_spend: HiOutlineQuestionMarkCircle,
  workspace_ratelimit: HiOutlineQuestionMarkCircle,
}

export const ProductIcon = ({ quota, ...props }: Props) => {
  const Icon = quota ? icon[quota] : null

  return Icon ? <Icon {...props} /> : <HiOutlineExclamationTriangle {...props} />
}
