import { Button, Callout, IconButton } from '@bpinternal/ui-kit'
import { Flex, Link } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { HiOutlineXMark } from 'react-icons/hi2'
import { CodeSnippet } from '~/components'
import { Input } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { getQueryOptions } from '~/services'
import { config as globalConfig } from '~/shared'
import { getUseConfigStore } from '~/features/webchat/stores/useConfigStore'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()

  const useConfigStore = getUseConfigStore({ botId, workspaceId })
  const webhookId = useConfigStore((state) => state.webhookId)
  const allowedOrigins = useConfigStore((state) => state.allowedOrigins)
  const setAllowedOrigins = useConfigStore((state) => state.setAllowedOrigins)
  const saveConfig = useConfigStore((state) => state.saveConfig)
  const _updating = useConfigStore((state) => state._updating)

  const workspace = useQuery({
    ...getQueryOptions('workspaces_/$workspaceId_', { workspaceId }),
    queryKey: [],
    gcTime: 0,
    staleTime: 0,
  }).data

  return (
    <Flex direction={'column'} gap={'6'}>
      <Flex direction={'column'} gap={'6'}>
        {webhookId && (
          <ConfigSection
            title="Client Id"
            description="Client id is used to identify the client in the webchat. Only use this if you want to use the client without the webchat interface."
            linkUrl="https://botpress.com/docs/developers/webchat-v2/javascript-client/"
            linkLabel="Documentation"
          >
            <CodeSnippet code={webhookId} />
          </ConfigSection>
        )}
        <ConfigSection
          title="Allowed Origins"
          description="List of origins that are allowed to access the webchat. Leave an empty list to allow all origins."
        >
          {workspace?.plan === 'community' ? (
            <Callout className="text-center">
              <div className="mb-3">This feature is only available for Team and Enterprise plan.</div>
              <Link href={`/workspaces/${workspaceId}/settings/billing/plans`}>
                <Button variant="soft" size={'1'}>
                  Upgrade Now
                </Button>
              </Link>
            </Callout>
          ) : (
            <Callout className="mb-2" color="amber">
              You must include <b>{globalConfig.dashboardUrl}</b> to still be able to use the chat tab in the dashboard.
            </Callout>
          )}
          <div className="mb-4 flex flex-col gap-2">
            {allowedOrigins?.map((origin: string, index: number) => (
              <div key={index} className="flex items-center gap-2">
                <Input
                  placeholder="http://example.com"
                  value={origin}
                  onChange={(e) => {
                    const newOrigins = [...allowedOrigins]
                    newOrigins[index] = e.target.value
                    setAllowedOrigins(newOrigins)
                  }}
                />
                <IconButton
                  icon={HiOutlineXMark}
                  variant="minimal"
                  color="red"
                  onClick={() => {
                    const newOrigins = [...allowedOrigins]
                    newOrigins.splice(index, 1)
                    setAllowedOrigins(newOrigins)
                  }}
                />
              </div>
            ))}
          </div>
          <div className="flex">
            {workspace && workspace.plan !== 'community' && (
              <Button variant="ghost" onClick={() => setAllowedOrigins([...(allowedOrigins ?? []), ''])}>
                Add Origin
              </Button>
            )}
          </div>
        </ConfigSection>
      </Flex>
      <Button loading={_updating} className="self-end" onClick={saveConfig}>
        Save Configuration
      </Button>
    </Flex>
  )
}
