import { forwardRef, type ComponentProps, type ReactNode } from 'react'
import { Flex, Button as ThemeButton } from '@radix-ui/themes'
import { button, minimal, dashed, spinner, invisible, truncate } from './button.module.css'
import { Spinner } from '../Spinner'
import { clsx } from 'clsx'

type Variant = ComponentProps<typeof ThemeButton>['variant'] | 'minimal' | 'dashed'

type ButtonProps = {
  variant?: Variant
  leading?: ReactNode
  trailing?: ReactNode
  loading?: boolean
} & Omit<ComponentProps<typeof ThemeButton>, 'variant'>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, leading, trailing, children, loading = false, disabled, size = '2', ...props }, ref) => {
    const spinnerSize = size === '1' ? '3' : size === '2' ? '4' : size === '3' ? '4' : '5'

    return (
      <ThemeButton
        {...props}
        disabled={disabled || loading}
        variant={variant === 'minimal' || variant === 'dashed' ? 'outline' : variant}
        size={size}
        className={clsx(button, className, {
          [minimal]: variant === 'minimal',
          [dashed]: variant === 'dashed',
        })}
        ref={ref}
      >
        {leading && <Flex className={clsx(loading && invisible)}>{leading}</Flex>}
        {children && <Flex className={clsx(truncate, loading && invisible)}>{children}</Flex>}
        {trailing && <Flex className={clsx(loading && invisible)}>{trailing}</Flex>}
        {loading && <Spinner className={spinner} size={spinnerSize} />}
      </ThemeButton>
    )
  }
)

Button.displayName = 'Button'
