import { useParams } from '@tanstack/react-router'
import { useState, useMemo, useEffect } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import '~/features/analytics/styles/gridLayout.css'
import { CheckboxGroup, Flex, Section, Text, Tooltip } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { userPreferencesQueryOptions } from '~/queries'
import {
  AnalyticsBoardConfig,
  Chart,
  chartColorNames,
  ChartConfig,
  ChartDataProvider,
  chartDateShortcuts,
  chartTypes,
  dateShortcutDetails,
  defaultDataKeys,
} from '~/features/analytics'
import { DropdownMenu, EmptyState, Icon, Link, MenuItem, Popover } from '~/elementsv2'
import { Button, IconButton, showConfirmationPrompt } from '@bpinternal/ui-kit'
import { useSetPreferences } from '~/hooks'
import AutosizeInput from 'react-input-autosize'
import useDebounceEffect from '~/hooks/useDebounceEffect'
import {
  HiChevronDown,
  HiOutlineCalendar,
  HiOutlineChartBarSquare,
  HiOutlineEllipsisVertical,
  HiOutlinePlus,
} from 'react-icons/hi2'
import { nanoid } from 'nanoid'
import { Boundary, Page } from '~/componentsV2'
import { defaultChartLayout, maxChartsPerPage, minW, version } from '~/features/analytics/constants'
import { cn } from '../../../utils'
import { getQueryOptions } from '../../../services'
import EmptyAnalyticsIcon from '~/assets/data-analytics-14.svg?react'

const ResponsiveGridLayout = WidthProvider(Responsive)

export const CustomAnalytics = () => {
  const { botId, workspaceId } = useParams({ from: '/workspaces/$workspaceId/bots/$botId/analytics' })
  const { plan } = useSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const prefsProps = { path: '$workspaceId/$botId/analyticsGridBoard', params: { botId, workspaceId } } as const
  const board = useSuspenseQuery(userPreferencesQueryOptions(prefsProps)).data

  // TODO: These checks are scattered across the app. We should have a single place to check for plan.
  const customAnalyticsEnabled = plan !== 'community' // MINIMUM_PLAN and above
  const tooltipUnsupportedPlanMessage = 'Only available on Plus plans and above.'
  const [analyticsBoard, setAnalyticsBoard] = useState<AnalyticsBoardConfig>(
    customAnalyticsEnabled ? board : defaultChartLayout
  )
  const { layouts, charts } = analyticsBoard
  const [transition, setTransition] = useState(false)

  const { mutate: updateBoard } = useSetPreferences()

  useEffect(() => {
    if (!transition) {
      setTransition(true)
    }
  }, [])

  useDebounceEffect(
    () => {
      void updateBoard({
        ...prefsProps,
        value: { ...analyticsBoard, charts: analyticsBoard.charts.slice(0, maxChartsPerPage) },
      })
    },
    [analyticsBoard],
    1000
  )

  const addChartMenu: MenuItem[] = chartTypes.map((type) => {
    const parsedType = type.replace('_', ' ')
    return {
      content: parsedType.charAt(0).toUpperCase() + parsedType.slice(1),
      type: 'item',
      onSelect: () => {
        const newId = nanoid()
        setAnalyticsBoard((prev) => ({
          ...prev,
          charts: [
            ...prev.charts,
            {
              id: newId,
              type,
              title: 'New Chart',
              values: [],
              showLegend: true,
              dateRange: { shortcut: 'lastMonth' },
            },
          ],
          layouts: {
            lg: [...prev.layouts.lg, { x: 0, y: 99, w: 5, h: 2, minW, i: newId }],
            sm: [...prev.layouts.sm, { x: 0, y: 99, w: 5, h: 2, minW, i: newId }],
          },
        }))
      },
    }
  })

  const editBoardMenu: MenuItem[] = [
    {
      type: 'submenu',
      content: 'Add Chart',
      items: addChartMenu,
      hidden: charts.length >= maxChartsPerPage,
    },
    {
      type: 'item',
      content: 'Add Chart',
      tooltip: 'You have reached the maximum number of charts allowed.',
      disabled: true,
      hidden: charts.length < maxChartsPerPage,
    },

    {
      type: 'item',
      content: 'Back to Default',
      onSelect: () =>
        void showConfirmationPrompt(
          'Are you sure you want to reset the analytics board to its default layout? This action cannot be undone.',
          {
            title: 'Reset Analytics Board',
          }
        ).then(() => setAnalyticsBoard(defaultChartLayout)),
    },
    { type: 'separator' },
    {
      type: 'item',
      content: 'Remove All Charts',
      color: 'red',
      onSelect: () =>
        void showConfirmationPrompt('Are you sure you want to delete all charts? This action cannot be undone.', {
          variant: 'danger',
          title: 'Delete All Charts',
        }).then(() => setAnalyticsBoard((prev) => ({ ...prev, charts: [] }))),
    },
  ]

  const children = useMemo(
    () =>
      charts.map(({ id, title, type, values, showLegend, dateRange }) => {
        const updateChart = (key: keyof ChartConfig, value: any) => {
          setAnalyticsBoard((prev) => ({
            ...prev,
            charts: prev.charts.map((chart) => (chart.id === id ? { ...chart, [key]: value } : chart)),
          }))
        }
        const chartMenuItems: MenuItem[] = [
          {
            type: 'checkBoxItem',
            content: showLegend ? 'Hide Legend' : 'Show Legend',
            checked: showLegend,
            showCheckmark: false,
            onCheckedChange: (checked) => updateChart('showLegend', checked),
          },
          {
            type: 'submenu',
            content: 'Type',
            items: [
              {
                type: 'radioGroup',
                value: type,
                items: chartTypes.map((chartType) => {
                  const parsedType = chartType.replace('_', ' ')
                  return {
                    content: parsedType.charAt(0).toUpperCase() + parsedType.slice(1),
                    value: chartType,
                    type: 'radioItem',
                    onSelect: () => updateChart('type', chartType),
                  }
                }),
              },
            ],
          },
          {
            content: 'Duplicate',
            type: 'item',
            disabled: charts.length >= maxChartsPerPage,
            tooltip:
              charts.length >= maxChartsPerPage ? 'You have reached the maximum number of charts allowed.' : undefined,
            onSelect: () => {
              const chart = charts.find((chartObj) => chartObj.id === id)
              const lgLayout = layouts.lg.find((layout) => layout.i === id)
              const smLayout = layouts.sm.find((layout) => layout.i === id)
              const newId = nanoid()
              if (chart) {
                setAnalyticsBoard((prev) => ({
                  version,
                  charts: [...prev.charts, { ...chart, id: newId }],
                  layouts: {
                    lg: [...prev.layouts.lg, { x: 0, y: 0, w: 4, h: 2, ...lgLayout, minW, i: newId }],
                    sm: [...prev.layouts.sm, { x: 0, y: 0, w: 4, h: 2, ...smLayout, minW, i: newId }],
                  },
                }))
              }
            },
          },
          { type: 'separator' },
          {
            content: 'Delete',
            color: 'red',
            type: 'item',
            onSelect: () =>
              setAnalyticsBoard((prev) => ({ ...prev, charts: prev.charts.filter((chart) => chart.id !== id) })),
          },
        ]

        const dateMenuItems: MenuItem[] = [
          {
            type: 'radioGroup',
            value: dateRange?.shortcut,
            items: chartDateShortcuts.map((shortcut) => ({
              content: dateShortcutDetails[shortcut].label,
              type: 'radioItem',
              value: shortcut,
              onSelect: () => {
                updateChart('dateRange', { shortcut })
              },
            })),
          },
        ]

        const dateShortcutButton = (
          <Button size={'1'} variant="soft" color="gray" className="group" disabled={!customAnalyticsEnabled}>
            <Text className="hidden @xs:block">
              {dateRange?.shortcut ? dateShortcutDetails[dateRange.shortcut].label : 'Select Date'}
            </Text>
            <Icon className="group-disabled:opacity-50 @xs:hidden" icon={HiOutlineCalendar} />
          </Button>
        )
        const dataSourceButton = (
          <Button
            data-no-dnd="true"
            disabled={!customAnalyticsEnabled}
            size={'1'}
            className="no-drag group"
            color="gray"
            variant="soft"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Text className="hidden @xs:block">Edit Source</Text>
            <Icon className="group-disabled:opacity-50 @xs:hidden" icon={HiOutlineChartBarSquare} />
          </Button>
        )

        const chartOptionsButton = (
          <IconButton
            size={'1'}
            variant="minimal"
            color="gray"
            icon={HiOutlineEllipsisVertical}
            disabled={!customAnalyticsEnabled}
          />
        )

        return (
          <div key={id} className="group">
            <Boundary className="size-full">
              <ChartDataProvider botId={botId} workspaceId={workspaceId} dateShortcut={dateRange?.shortcut}>
                {({ data, resolution }) => (
                  <Chart
                    resolution={resolution}
                    className="overflow-visible @container"
                    showLegend={showLegend}
                    type={type as any}
                    categories={values as any}
                    data={data}
                    key={id}
                  >
                    <Flex align={'center'} gap={'2'}>
                      {customAnalyticsEnabled ? (
                        <>
                          <Text
                            asChild
                            size={'2'}
                            mr={'auto'}
                            weight={'medium'}
                            ml={'2'}
                            truncate
                            className={cn('no-drag group ', {
                              'border-b border-transparent focus-within:!border-gray-9 hover:border-gray-5': title,
                            })}
                          >
                            <AutosizeInput
                              placeholder="Chart Title"
                              inputClassName="[all:unset] hover:placeholder-gray-8 focus:placeholder-gray-8 placeholder-transparent"
                              value={title}
                              onChange={(e) => updateChart('title', e.target.value)}
                              onKeyDown={(e) => e.key === 'Enter' && (e.target as any).blur()}
                            />
                          </Text>
                          <DropdownMenu content={dateMenuItems} variant="soft" color="gray">
                            {dateShortcutButton}
                          </DropdownMenu>
                          <Popover maxHeight={'32rem'} size={'1'} trigger={dataSourceButton}>
                            <CheckboxGroup.Root
                              className="gap-3"
                              variant="soft"
                              value={values}
                              onValueChange={(value) => {
                                updateChart('values', value.length >= 10 ? value.slice(-10) : value)
                              }}
                            >
                              {[...new Set([...defaultDataKeys, ...data.flatMap((d) => Object.keys(d))])]
                                .filter((key) => key !== 'timestamp')
                                .map((key) => (
                                  <CheckboxGroup.Item
                                    data-accent-color={chartColorNames[values.indexOf(key)] ?? 'gray'}
                                    key={key}
                                    value={key}
                                    className={cn('cursor-pointer [&>*]:cursor-pointer', {
                                      'before:[&>button]:border before:[&>button]:border-gray-6 before:[&>button]:bg-inherit':
                                        !chartColorNames[values.indexOf(key)],
                                    })}
                                  >
                                    {key}
                                  </CheckboxGroup.Item>
                                ))}
                            </CheckboxGroup.Root>
                          </Popover>
                          <DropdownMenu content={chartMenuItems} variant="soft" color="gray">
                            {chartOptionsButton}
                          </DropdownMenu>
                        </>
                      ) : (
                        <>
                          <Text size={'2'} weight={'medium'} ml={'2'} mr={'auto'} truncate>
                            {title}
                          </Text>
                          <Tooltip content={tooltipUnsupportedPlanMessage}>{dateShortcutButton}</Tooltip>
                          <Tooltip content={tooltipUnsupportedPlanMessage}>{dataSourceButton}</Tooltip>
                          <Tooltip content={tooltipUnsupportedPlanMessage}>{chartOptionsButton}</Tooltip>
                        </>
                      )}
                    </Flex>
                  </Chart>
                )}
              </ChartDataProvider>
            </Boundary>
            {customAnalyticsEnabled && (
              <div className="absolute bottom-1 right-1 hidden size-1.5 border-b-2 border-r-2 border-gray-8 group-hover:block" />
            )}
          </div>
        )
      }),
    [charts, botId, workspaceId, transition]
  )

  const editBoardButton = (
    <Button disabled={!customAnalyticsEnabled} color="gray" variant="outline" trailing={<HiChevronDown />}>
      Edit Board
    </Button>
  )

  return (
    <Page
      title="Analytics"
      actions={
        customAnalyticsEnabled ? (
          <DropdownMenu color="gray" content={editBoardMenu}>
            {editBoardButton}
          </DropdownMenu>
        ) : (
          <Tooltip content={tooltipUnsupportedPlanMessage}>{editBoardButton}</Tooltip>
        )
      }
    >
      {analyticsBoard.charts.length > 0 ? (
        <Flex direction={'column'} gap={'6'}>
          <ResponsiveGridLayout
            isResizable={customAnalyticsEnabled}
            isDraggable={customAnalyticsEnabled}
            verticalCompact
            useCSSTransforms={false}
            className={cn('layout -m-3', { animated: transition })}
            layouts={layouts}
            onLayoutChange={(_, allLayouts) => {
              setAnalyticsBoard((prev) => ({ ...prev, layouts: allLayouts }) as any)
            }}
            cols={{ lg: 12, sm: 2 }}
            breakpoints={{ lg: 1024, sm: 0 }}
            rowHeight={100}
            resizeHandles={['se']}
            draggableCancel=".no-drag"
            margin={[12, 12]}
          >
            {children}
          </ResponsiveGridLayout>
          {customAnalyticsEnabled ? (
            charts.length < maxChartsPerPage && (
              <Button
                variant="dashed"
                className="h-16"
                size={'3'}
                leading={<HiOutlinePlus />}
                onClick={() => {
                  const newId = nanoid()
                  setAnalyticsBoard((prev) => ({
                    ...prev,
                    charts: [
                      ...prev.charts,
                      {
                        id: newId,
                        type: 'area',
                        title: 'New Chart',
                        values: [],
                        showLegend: true,
                        dateRange: { shortcut: 'lastMonth' },
                      },
                    ],
                    layouts: {
                      lg: [...prev.layouts.lg, { x: 0, y: 99, w: 5, h: 2, minW, i: newId }],
                      sm: [...prev.layouts.sm, { x: 0, y: 99, w: 5, h: 2, minW, i: newId }],
                    },
                  }))
                }}
              >
                Add Chart
              </Button>
            )
          ) : (
            <Flex justify={'center'} align={'center'} p={'6'} className="border border-dashed border-gray-5 " gap={'2'}>
              <Text size={'2'} color="gray">
                Custom Analytics are not available on the Pay-as-you-go plan. Please,
              </Text>
              <Link size={'2'} to={'/workspaces/$workspaceId/billing/plans'} params={{ workspaceId }}>
                upgrade your plan
              </Link>
              <Text size={'2'} color="gray">
                to access this feature.
              </Text>
            </Flex>
          )}
        </Flex>
      ) : (
        <Section size={'4'}>
          <EmptyState
            mx={'auto'}
            maxWidth={'var(--container-2'}
            icon={EmptyAnalyticsIcon}
            title={'Create Your Custom Dashboard'}
            description={
              'Start building your personalized analytics dashboard. Add and arrange charts that matter most to you and begin exploring your data insights in a way that suits your needs.'
            }
            primaryAction={
              <DropdownMenu color="gray" content={addChartMenu} side="right">
                <Button trailing={<HiOutlinePlus />}>Add Charts</Button>
              </DropdownMenu>
            }
          />
        </Section>
      )}
    </Page>
  )
}
