import { forwardRef, useRef } from 'react'
import {
  container,
  headerContainer,
  sidebarContainer,
  contentCard,
  appContent,
  appLarge,
  appSmall,
  card,
  dialogContent,
  dialogPortal,
  smallHeader,
} from './sidebar-app.module.css'
import { clsx } from 'clsx'
import { Box, Flex, VisuallyHidden } from '@radix-ui/themes'
import { Card } from '../Card'
import useMeasure from 'react-use-measure'
import { mergeRefs } from 'react-merge-refs'
import { Menu } from 'lucide-react'
import { IconButton } from '../IconButton'
import { Dialog } from 'radix-ui'
import { useMediaQuery } from 'usehooks-ts'

type Props = {
  header: React.ReactNode
  sidebar: React.ReactNode
  className?: string
} & React.HTMLAttributes<HTMLDivElement>

export const SidebarApp = forwardRef<HTMLDivElement, Props>(
  ({ children, header, sidebar, className, style, ...props }, ref) => {
    const [measureHeaderRef, { height: headerHeight }] = useMeasure()
    const [measureContainerRef, { height: containerHeight }] = useMeasure()
    const dialogPortalRef = useRef<HTMLDivElement>(null)

    // This needs to match the media query in the CSS
    const matches = useMediaQuery('(min-width: 1024px)')

    return (
      <Flex
        style={
          {
            '--header-height': `${headerHeight}px`,
            '--container-height': `${containerHeight}px`,
            ...style,
          } as React.CSSProperties
        }
        direction={'column'}
        className={clsx(className, container)}
        ref={mergeRefs([ref, measureContainerRef])}
        {...props}
      >
        {matches ? (
          <div className={appLarge}>
            <div className={headerContainer} ref={measureHeaderRef}>
              {header}
            </div>
            <Flex className={appContent}>
              <Box className={sidebarContainer}>{sidebar}</Box>
              <Card className={clsx(contentCard, card)}>{children}</Card>
            </Flex>
          </div>
        ) : (
          <div className={dialogPortal} ref={dialogPortalRef}>
            <Dialog.Root>
              <div className={appSmall}>
                <Flex direction={'column'}>
                  <Flex align={'center'} className={headerContainer}>
                    <Dialog.Trigger asChild>
                      <IconButton variant="minimal" color="gray" size={'2'} icon={Menu} />
                    </Dialog.Trigger>
                    <Dialog.Portal container={dialogPortalRef.current}>
                      <Dialog.Content className={dialogContent} aria-describedby={undefined}>
                        <VisuallyHidden>
                          <Dialog.Title>Menu</Dialog.Title>
                        </VisuallyHidden>
                        <Card className={clsx(sidebarContainer, card)}>{sidebar}</Card>
                      </Dialog.Content>
                    </Dialog.Portal>
                    <div className={smallHeader}>{header}</div>
                  </Flex>
                  {children}
                </Flex>
              </div>
            </Dialog.Root>
          </div>
        )}
      </Flex>
    )
  }
)

SidebarApp.displayName = 'SidebarApp'
