import { createFileRoute } from '@tanstack/react-router'
import {
  COMMUNITY_PLAN_PRODUCT_ID,
  PLUS_PLAN_PRODUCT_ID,
  TEAM_PLAN_PRODUCT_ID,
  useBilling,
  type Plan,
} from '~/features/usage/useBillingLegacy'
import { useMemo, Fragment } from 'react'
import { computeTotalCostLegacy as computeTotalCost } from '~/features/billing/utils'
import { useCheckout, useQueryKeys } from '~/hooks'
import { Badge, Flex, Grid, Separator, Text } from '@radix-ui/themes'

import { cn, dollars } from '~/utils'
import { ProductIcon } from '~/features/billing/componentsV2'

import { isEqual } from 'lodash'
import { Color } from '~/types'
import { trackEvent } from '~/providers'
import { Button, IconButton, showConfirmationPrompt } from '@bpinternal/ui-kit'
import { Minus, Plus } from 'lucide-react'

type BillingProps = ReturnType<typeof useBilling>

const isProductPlan = (productId: string) => productId === TEAM_PLAN_PRODUCT_ID || productId === PLUS_PLAN_PRODUCT_ID

const getPlanColor = (plan: Plan) => {
  switch (plan) {
    case 'team':
      return 'grass'
    case 'enterprise':
      return 'iris'
    case 'plus':
      return 'indigo'
    default:
      return 'gray'
  }
}

export function AddOnsSectionLegacy({ workspaceId }: { workspaceId: string }) {
  const { initialProductQuantityMap, productQuantityMap, updateProductQuantity, plan, promoCode, ...billingProps } =
    useBilling(workspaceId)

  const initialTotalCost = useMemo(
    () => computeTotalCost(initialProductQuantityMap, promoCode),
    [productQuantityMap, promoCode]
  )
  const totalCost = useMemo(() => computeTotalCost(productQuantityMap, promoCode), [productQuantityMap, promoCode])

  const { mutate: handleCheckout, isPending } = useCheckout(workspaceId, {
    productQuantityMap,
    initialProductQuantityMap,
    promoCode,
    ...billingProps,
  })

  const billingReadonly = billingProps.billingInfo?.billingReadonly

  const products = [...Object.entries(productQuantityMap)]
    .filter(
      ([productId, product]) =>
        productId !== COMMUNITY_PLAN_PRODUCT_ID && !(isProductPlan(productId) && !product.quantity)
    )
    .sort(([productId]) => (productId === TEAM_PLAN_PRODUCT_ID || productId === PLUS_PLAN_PRODUCT_ID ? 1 : -1))
    .map(([productId, { quantity, productDetails }]) => {
      const initialQuantity = initialProductQuantityMap[productId]?.quantity ?? 0
      const countColor: Color | undefined =
        quantity > initialQuantity ? 'grass' : quantity < initialQuantity ? 'red' : quantity === 0 ? 'gray' : undefined

      const priceCents = productDetails.price.unit_amount ?? 0

      let discountedAmount = undefined
      if (promoCode?.coupon.appliesTo.includes(productId) && quantity > 0) {
        discountedAmount = ((priceCents * promoCode.coupon.percentOff) / 100) * quantity
      }

      return {
        quota: productDetails.metadata.quotaName,
        isPlan: isProductPlan(productId),
        quantity,
        initialQuantity,
        productId,
        countColor,
        description: productDetails.description,
        name: productDetails.name.replace(/Add-on - /i, ''),
        price: priceCents / 100,
        discountedAmount: discountedAmount ? discountedAmount / 100 : undefined,
      }
    })

  const totalDiscount: number = products.reduce((acc, { discountedAmount }) => acc + (discountedAmount ?? 0), 0)
  return (
    <Flex direction={'column'} gap={'3'}>
      <Grid align={'center'} gapX={'4'} gapY={'5'} className="grid-cols-[auto,1fr,auto]">
        {products.map(
          ({
            initialQuantity,
            isPlan,
            name,
            price,
            quantity,
            quota,
            productId,
            description,
            countColor,
            discountedAmount,
          }) => (
            <Fragment key={productId}>
              <Flex align={'center'} gap={'2'} className={cn({ invisible: isPlan })}>
                <IconButton
                  variant="minimal"
                  size={'1'}
                  color="gray"
                  disabled={!quantity || billingReadonly}
                  onClick={(e) => updateProductQuantity(productId, e.shiftKey ? -10 : -1)}
                  icon={Minus}
                />
                <Text className={cn({ 'opacity-75': !quantity })} color={countColor}>
                  {quantity}
                </Text>
                <IconButton
                  variant="minimal"
                  size={'1'}
                  color="gray"
                  disabled={billingReadonly}
                  onClick={(e) => updateProductQuantity(productId, e.shiftKey ? 10 : 1)}
                  icon={Plus}
                />
              </Flex>
              {!isPlan || (isPlan && quantity) > 0 ? (
                <Flex gap={'4'} align={'center'}>
                  <Badge className="h-9 w-9 rounded" color={isPlan ? getPlanColor(plan) : undefined}>
                    <ProductIcon quota={isPlan ? 'team_plan' : (quota as any)} className="h-full w-full" />
                  </Badge>
                  <Flex direction={'column'} gap={'1'}>
                    <Text size={'2'} weight={'medium'}>
                      {name}
                    </Text>
                    <Text size={'1'} color="gray">
                      {description}
                    </Text>
                  </Flex>
                </Flex>
              ) : null}
              <Flex direction={'column'} gap={'1'} justify={'end'} pr={'4'} align={'end'}>
                <Flex gap={'2'}>
                  {initialQuantity && quantity !== initialQuantity ? (
                    <Text size={'2'} color="gray" className="italic line-through opacity-50">
                      ${dollars(initialQuantity * price)}{' '}
                    </Text>
                  ) : null}
                  {quantity || initialQuantity ? (
                    <Text size={'2'} color="gray">
                      ${dollars(quantity * price)}
                    </Text>
                  ) : null}
                </Flex>

                {discountedAmount && (
                  <Text size={'2'} color="lime">
                    -${dollars(discountedAmount)}
                  </Text>
                )}
              </Flex>
            </Fragment>
          )
        )}

        <Flex gap={'2'} direction={'column'} justify={'end'} className="col-start-2" pt={'2'}>
          {totalDiscount > 0 && (
            <Text size={'2'} weight={'medium'} color="gray">
              Total Discount
            </Text>
          )}
          <Text weight={'medium'}>Total Add-ons cost per month</Text>
        </Flex>
        <Flex justify={'end'} direction={'column'} align={'end'} pr={'4'} gap={'2'} pt={'2'}>
          {totalDiscount > 0 && (
            <Text weight={'medium'} color="lime" size={'2'}>
              -${dollars(totalDiscount)}
            </Text>
          )}
          <Flex gap={'2'}>
            {initialTotalCost !== totalCost && (
              <Text className="italic line-through opacity-60" color="gray">
                ${dollars(initialTotalCost)}
              </Text>
            )}
            <Text weight={'medium'}>${dollars(totalCost)}</Text>
          </Flex>
        </Flex>
      </Grid>
      <Separator size="4" />
      <Button
        ml={'auto'}
        className="col-start-2 w-fit"
        disabled={isEqual(productQuantityMap, initialProductQuantityMap) || billingReadonly || isPending}
        loading={isPending}
        onClick={() => {
          trackEvent({
            type: 'update_addons',
            state: 'clicked',
            upgrade: totalCost > initialTotalCost,
            workspaceId,
            plan,
          })
          void showConfirmationPrompt(
            <Text>
              Your new plan will cost <Text weight={'bold'}> ${dollars(totalCost)}</Text> per month including add-ons
              and your plan. Are you sure you want to proceed?
            </Text>,
            {
              title: 'Update plan',
            }
          ).then(() => {
            trackEvent({
              type: 'update_addons',
              state: 'confirmed',
              upgrade: totalCost > initialTotalCost,
              workspaceId,
              plan,
            })

            handleCheckout()
          })
        }}
      >
        <Text>Update plan</Text>
      </Button>
    </Flex>
  )
}
