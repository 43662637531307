import { Callout, DialogFooter } from '@bpinternal/ui-kit'
import { Flex, Text } from '@radix-ui/themes'
import { FC } from 'react'
import { useCopyToBot } from '~/hooks/bots/useCopyToBot'
import { trackEvent } from '~/providers'

type CopyToBotDialogProps = {
  close: () => void
  workspaceId: string
  sourceBotName: string
  targetBotName: string
  sourceBotId: string
  targetBotId: string
}

export const CopyToBotDialog: FC<CopyToBotDialogProps> = ({
  close,
  workspaceId,
  sourceBotName,
  targetBotName,
  sourceBotId,
  targetBotId,
}) => {
  const { mutate: copyBot, isPending: isCopying } = useCopyToBot()

  return (
    <Flex direction="column" gap="2">
      <>
        <Callout color="amber">
          <Text size="2">
            This will copy all settings and workflows from &quot;{sourceBotName}&quot; to &quot;{targetBotName}
            &quot;. This cannot be undone.
          </Text>
        </Callout>
        <DialogFooter
          disabled={isCopying}
          showCancel
          confirmLabel="Copy"
          onCancel={close}
          onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            trackEvent({
              type: 'request_copy_to_bot_from_dashboard',
              sourceBotId,
              targetBotId,
            })
            copyBot({
              workspaceId,
              sourceBotId,
              targetBotId,
              onSettled: close,
              onSuccess: () => {
                trackEvent({
                  type: 'copy_to_bot_success_from_dashboard',
                  sourceBotId,
                  targetBotId,
                })
              },
              onError: (error) => {
                trackEvent({
                  type: 'copy_to_bot_error_from_dashboard',
                  sourceBotId,
                  targetBotId,
                  error: error.message,
                })
              },
            })
          }}
        />
      </>
    </Flex>
  )
}
