import React, { useMemo } from 'react'
import { Flex, Text } from '@radix-ui/themes'
import { Select, SelectProps, SelectMenuItems } from '~/elementsv2'
import { UpgradeBadge } from '~/elementsv2/UpgradeBadge'
import { Plan } from '~/features/usage/useBilling'
import { isPaywalled } from '~/utils/monetization'
import { useMonetizationPrompt } from './MonetizationPrompt'
import { trackEvent } from '~/providers'

type SelectItem = Extract<SelectMenuItems, { type: 'item' }>
type MonetizedSelectItem = SelectItem & {
  minPlan: Plan
}
type Item = SelectMenuItems | MonetizedSelectItem

export interface MonetizedSelectProps extends SelectProps {
  items: Item[]
  currentPlan: Plan
  feature: string
  workspaceId: string
  featureMetric?: string
}

/**
 * MonetizedSelect renders a Select with items that can be paywalled.
 * Items whose `minPlan` is greater than the current plan will be paywalled.
 */
export function MonetizedSelect({
  items,
  currentPlan,
  feature,
  workspaceId,
  featureMetric,
  ...props
}: MonetizedSelectProps) {
  const triggerMonetizationPrompt = useMonetizationPrompt(feature, workspaceId)
  const transformedItems = useMemo(
    () =>
      items.map((item) => {
        const isMonetized = 'minPlan' in item
        const isItemPaywalled = isMonetized && isPaywalled(currentPlan, item.minPlan)
        if (!isItemPaywalled) {
          return item
        }
        let content = item.content
        if (typeof content === 'string') {
          content = <Text>{content}</Text>
        }
        return {
          ...item,
          content: (
            <Flex align="start" justify="between" gap="8">
              {content}
              <UpgradeBadge />
            </Flex>
          ),
        }
      }),
    [items, currentPlan]
  )
  const handleValueChange = (selectedValue: string) => {
    const selectedItem = transformedItems.find(
      (item): item is MonetizedSelectItem => item.type === 'item' && item.value === selectedValue
    )
    if (!selectedItem) {
      return
    }
    if (isPaywalled(currentPlan, selectedItem.minPlan)) {
      if (featureMetric) {
        trackEvent({
          type: 'paywall_triggered',
          feature: featureMetric,
          fromPlan: currentPlan,
          workspaceId,
        })
      }
      void triggerMonetizationPrompt(selectedItem.minPlan)
    } else if (props.onValueChange) {
      props.onValueChange(selectedValue)
    }
  }

  return <Select {...props} onValueChange={handleValueChange} items={transformedItems} />
}
