import { Flex, Separator, Text } from '@radix-ui/themes'
import { ReactNode } from 'react'

export const PageSection = ({
  title,
  children,
  actions,
  ...props
}: {
  title: ReactNode
  children?: ReactNode
  actions?: ReactNode
  className?: string
}) => {
  return (
    <Flex {...props} direction="column" gap="3">
      <Flex justify={'between'} align={'center'}>
        <Text size="3" weight={'medium'}>
          {title}
        </Text>
        <div>{actions}</div>
      </Flex>
      <Separator size="4" />
      {children}
    </Flex>
  )
}
