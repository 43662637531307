import { useLayoutEffect, useMemo, useRef } from 'react'
import { createFileRoute } from '@tanstack/react-router'
import Editor, { Monaco } from '@monaco-editor/react'
import { Popover, Select } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchat } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey } from '~/services'
import { Flex, Text, SegmentedControl, Slider } from '@radix-ui/themes'
import { HexColorPicker } from 'react-colorful'
import {
  tomato,
  red,
  ruby,
  crimson,
  pink,
  plum,
  purple,
  violet,
  iris,
  indigo,
  blue,
  cyan,
  teal,
  jade,
  green,
  grass,
  orange,
  amber,
} from '@radix-ui/colors'
import { HexColorInput } from '~/componentsV2'
import { webchatClasses } from '@botpress/webchat'
import { Button } from '@bpinternal/ui-kit'
import { getUseConfigStore } from '~/features/webchat/stores/useConfigStore'
import { useLocalStore } from '../../../../../../../stores'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2/theme')({
  component: Component,
})

export const baseColors = {
  tomato,
  red,
  ruby,
  crimson,
  pink,
  plum,
  purple,
  violet,
  iris,
  indigo,
  blue,
  cyan,
  teal,
  jade,
  green,
  grass,
  orange,
  amber,
} as const

const pickerColors = Object.values(baseColors).map((palette) => Object.values(palette)[8] ?? '')

type StandaloneEditor = ReturnType<Monaco['editor']['create']>

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const useConfigStore = useMemo(() => getUseConfigStore({ botId, workspaceId }), [])

  const color = useConfigStore((state) => state.color)
  const themeMode = useConfigStore((state) => state.themeMode)
  const variant = useConfigStore((state) => state.variant)
  const fontFamily = useConfigStore((state) => state.fontFamily)
  const radius = useConfigStore((state) => state.radius)
  const customStylesheet = useConfigStore((state) => state.customStyleSheet)
  const setCustomStylesheet = useConfigStore((state) => state.setCustomStyleSheet)
  const setThemeMode = useConfigStore((state) => state.setThemeMode)
  const setVariant = useConfigStore((state) => state.setVariant)
  const setColor = useConfigStore((state) => state.setColor)
  const setFont = useConfigStore((state) => state.setFontFamily)
  const setRadius = useConfigStore((state) => state.setRadius)
  const saveConfig = useConfigStore((state) => state.saveConfig)
  const _updating = useConfigStore((state) => state._updating)

  const darkMode = useLocalStore((s) => s.theme)
  const editorRef = useRef<StandaloneEditor | null>(null)

  const handleEditorDidMount = (editor: StandaloneEditor, monaco: Monaco) => {
    editorRef.current = editor

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      formatEditor()
    })

    monaco.languages.css.cssDefaults.setOptions({
      validate: true,
    })

    monaco.languages.registerCompletionItemProvider('css', {
      provideCompletionItems: () => {
        const suggestions: any[] = []

        function traverseClasses(obj: Record<string, any>) {
          for (const key in obj) {
            const value = obj[key]
            if (value && typeof value === 'object') {
              traverseClasses(value)
            } else if (key === 'className' && typeof value === 'string') {
              const classes = value.split(' ')
              for (const className of classes) {
                if (!suggestions.some((s) => s.label === `.${className}`)) {
                  suggestions.push({
                    label: `.${className}`,
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertText: `.${className}`,
                  })
                }
              }
            }
          }
        }
        traverseClasses(webchatClasses)

        return { suggestions }
      },
    })
  }

  const formatEditor = () => {
    editorRef.current?.getAction('editor.action.formatDocument')?.run()
  }

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection title="Theme styler" description="Customize the look and feel of your bot.">
        <Flex direction={'column'} gap={'4'}>
          <Flex direction={'column'} gap={'1'}>
            <Text size={'2'} color="gray">
              Theme
            </Text>
            <Flex align={'center'} gap={'4'}>
              <Select
                variant="soft"
                value={themeMode}
                onValueChange={(value) => {
                  setThemeMode(value as 'light' | 'dark')
                }}
                color="gray"
                items={[
                  { type: 'item', value: 'light', content: 'Light' },
                  { type: 'item', value: 'dark', content: 'Dark' },
                ]}
              />
              <Popover
                className="flex w-min flex-col gap-2"
                trigger={<div className="size-7 cursor-pointer rounded" style={{ background: color }} />}
              >
                <HexColorPicker color={color} onChange={setColor} />
                <Flex wrap={'wrap'} className="gap-1.5">
                  {pickerColors.map((presetColor) => (
                    <button
                      type="button"
                      key={presetColor}
                      className="size-7 rounded"
                      style={{ background: presetColor }}
                      onClick={() => setColor(presetColor)}
                    />
                  ))}
                </Flex>
                <HexColorInput value={color} onChange={setColor} />
              </Popover>
            </Flex>
          </Flex>
          <Flex direction={'column'} gap={'1'} className="w-fit">
            <Text size={'2'} color="gray">
              Variant
            </Text>
            <SegmentedControl.Root
              value={variant}
              onValueChange={(value) => {
                setVariant(value as 'soft' | 'solid')
              }}
            >
              <SegmentedControl.Item value="soft">Soft</SegmentedControl.Item>
              <SegmentedControl.Item value="solid">Solid</SegmentedControl.Item>
            </SegmentedControl.Root>
          </Flex>
          <Flex direction={'column'} gap={'1'} className="w-64">
            <Text size={'2'} color="gray">
              Radius
            </Text>
            <Slider
              value={[radius ?? 1]}
              onValueChange={(value) => {
                setRadius(value[0] ?? 1)
              }}
              min={0.5}
              max={4}
              step={0.5}
            />
          </Flex>
          <Flex direction={'column'} gap={'1'} className="w-32">
            <Text size={'2'} color="gray">
              Font
            </Text>
            <Select
              variant="soft"
              value={fontFamily}
              onValueChange={(value) => {
                setFont(value as 'rubik' | 'inter' | 'ibm' | 'fira')
              }}
              color="gray"
              items={[
                { type: 'item', value: 'rubik', content: 'Rubik' },
                { type: 'item', value: 'inter', content: 'Inter' },
                { type: 'item', value: 'ibm', content: 'IBM Plex Sans' },
                { type: 'item', value: 'fira', content: 'Fira Code' },
              ]}
            />
          </Flex>
        </Flex>
      </ConfigSection>
      <ConfigSection title="Styles" description="Custom styles for your bot.">
        <Flex gap="4" direction={'column'}>
          <div className="rounded-sm border border-gray-6 py-1">
            <Editor
              height={400}
              language="css"
              theme={darkMode === 'dark' ? 'vs-dark' : 'light'}
              defaultValue="/* Your CSS styles here */"
              value={customStylesheet}
              onChange={setCustomStylesheet}
              onMount={handleEditorDidMount}
            />
          </div>
          <Button loading={_updating} className="self-end" onClick={saveConfig}>
            Save Configuration
          </Button>
        </Flex>
      </ConfigSection>
    </Flex>
  )
}
