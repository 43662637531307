import { createFileRoute, useRouter } from '@tanstack/react-router'
import { Spinner } from '~/elementsv2'
import { Badge, Flex, Grid, Text, SegmentedControl, Link } from '@radix-ui/themes'
import { Color } from '~/types'
import { ReactNode, useState } from 'react'
import { cn, dollars } from '~/utils'
import { ClientReturn } from 'botpress-client'
import { billingPlan } from '~/features/billing/constants'
import {
  type BillingVersion,
  COMMUNITY_PLAN_PRODUCT_ID,
  PLUS_PLAN_PRODUCT_ID,
  TEAM_PLAN_PRODUCT_ID,
  useBilling,
} from '~/features/usage/useBilling'
import { getQueryOptions } from '~/services'
import { useMonthlyCheckout, useYearlyCheckout, useCustomerPortal, useFeature } from '~/hooks'
import { computeTotalCost } from '~/features/billing/utils'
import { useSuspenseQuery } from '@tanstack/react-query'
import { BillingReadonlyCallout } from '~/features/billing/componentsV2'
import { Card, Button, showConfirmationPrompt, ThemeColor } from '@bpinternal/ui-kit'
import { isSubscribedToPrice, getPlanPricing, isDowngradeFromYearly } from '~/features/billing/helpers'
import { Check } from 'lucide-react'
import { PlanInclusions } from '~/features/billing/PlanInclusions'
import { PlansLegacy } from '../../../../features/billing/componentsV2/BillingLegacy/plans-legacy'

export const Route = createFileRoute('/workspaces/$workspaceId/billing/plans')({
  component: () => {
    const isYearlyBillingEnabled = useFeature('billing-yearly-plans')
    const { workspaceId } = Route.useParams()

    if (!isYearlyBillingEnabled) {
      return <PlansLegacy workspaceId={workspaceId} />
    } else {
      return <Component />
    }
  },
})

const showYearlyDowngradeDialog = (openCustomerPortal: () => void) => {
  void showConfirmationPrompt(
    <Text>
      You must first cancel your current plan and resubscribe at the end of your billing cycle.
      <div className="py-2">
        <Link onClick={() => openCustomerPortal()} className="hover:cursor-pointer hover:underline" size={'2'}>
          Manage your subscription
        </Link>
      </div>
    </Text>,
    {
      confirmLabel: 'OK',
      title: 'Subscription management',
    }
  )
}

function Component() {
  const { workspaceId } = Route.useParams()
  const billingProps = useBilling(workspaceId)
  const billingReadonly = billingProps.billingInfo?.billingReadonly

  //TODO: This spinner has been done the lazy way, it should be replaced with a skeleton loader
  return billingProps.isLoading ? (
    <Flex width={'100%'} align={'center'} justify={'center'} height={'9'}>
      <Spinner />
    </Flex>
  ) : (
    <>
      {billingReadonly && <BillingReadonlyCallout />}
      <PlanSelection {...billingProps} />
    </>
  )
}

type BillingProps = ReturnType<typeof useBilling>
const PlanSelection = (props: BillingProps) => {
  const { workspaceId } = Route.useParams()
  const navigate = useRouter().navigate

  //Avoid using cache since we can't invalidate it when customer changes plan in stripe customer portal
  const { plan } = useSuspenseQuery({
    ...getQueryOptions('workspaces_/$workspaceId_', { workspaceId }),
    queryKey: [],
    gcTime: 0,
    staleTime: 0,
  }).data

  const { setProductQuantity, productQuantityMap, promoCode, subscriptions, ...billingProps } = props
  const [isYearly, setIsYearly] = useState(false)

  const onUpdateSubscription = () => {
    void navigate({
      to: '/workspaces/$workspaceId/billing',
      params: { workspaceId },
    })
  }

  const { mutate: handleMonthlyCheckout } = useMonthlyCheckout(
    workspaceId,
    {
      productQuantityMap,
      promoCode,
      ...billingProps,
    },
    onUpdateSubscription
  )

  const { mutate: handleYearlyCheckout } = useYearlyCheckout(workspaceId, billingProps, onUpdateSubscription)

  const { mutate: openCustomerPortal } = useCustomerPortal(workspaceId)

  const {
    price: teamPrice,
    pricePerMonth: teamPricePerMonth,
    pricePerYear: teamPricePerYear,
  } = getPlanPricing(TEAM_PLAN_PRODUCT_ID, isYearly, productQuantityMap)
  const {
    price: plusPrice,
    pricePerMonth: plusPricePerMonth,
    pricePerYear: plusPricePerYear,
  } = getPlanPricing(PLUS_PLAN_PRODUCT_ID, isYearly, productQuantityMap)

  const communityPrice = billingProps.products?.find((product) => product.id === COMMUNITY_PLAN_PRODUCT_ID)?.prices[0]

  const isLegacyPricing = props.billingVersion === 'v1' || props.billingVersion === 'v2'

  let discountedTeamPlanPrice: number | undefined = undefined
  if (promoCode?.coupon.appliesTo?.includes(TEAM_PLAN_PRODUCT_ID)) {
    discountedTeamPlanPrice = ((teamPrice?.unit_amount ?? 0) / 100) * (1 - promoCode.coupon.percentOff / 100)
  }

  let discountedPlusPlanPrice: number | undefined = undefined
  if (promoCode?.coupon.appliesTo?.includes(PLUS_PLAN_PRODUCT_ID)) {
    discountedPlusPlanPrice = ((plusPrice?.unit_amount ?? 0) / 100) * (1 - promoCode.coupon.percentOff / 100)
  }

  const billingReadonly = props.billingInfo?.billingReadonly
  const isEnterprise = plan === 'enterprise' || billingReadonly

  const isPlusButtonDisabled = isEnterprise || isSubscribedToPrice(plusPrice?.id ?? '', subscriptions ?? [])
  const isTeamButtonDisabled = isEnterprise || isSubscribedToPrice(teamPrice?.id ?? '', subscriptions ?? [])

  const planProductIds = [COMMUNITY_PLAN_PRODUCT_ID, TEAM_PLAN_PRODUCT_ID, PLUS_PLAN_PRODUCT_ID]
  const currentPlanPrice = (subscriptions ?? [])
    .flatMap((sub) => sub.items.data)
    .find((item) => planProductIds.includes(item.price.product.toString()))?.price

  return (
    <Flex direction={'column'} className="gap-3 @container">
      <Flex justify={'center'}>
        <SegmentedControl.Root defaultValue="monthly" onValueChange={(value) => setIsYearly(value === 'yearly')}>
          <SegmentedControl.Item value="monthly">
            <Text weight={'bold'} size={'1'} className={cn({ 'text-gray-9': isYearly })}>
              Monthly
            </Text>
          </SegmentedControl.Item>
          <SegmentedControl.Item value="yearly">
            <Text weight={'bold'} size={'1'} className={cn({ 'text-gray-9': !isYearly })}>
              Yearly
            </Text>
          </SegmentedControl.Item>
        </SegmentedControl.Root>
      </Flex>
      <Grid
        gap={'6'}
        className={`${isLegacyPricing ? 'grid-cols-1 @3xl:grid-cols-3' : 'grid-cols-1 @lg:grid-cols-2 @3xl:grid-cols-4'}`}
      >
        <PlanCard
          plan="community"
          billingVersion={props.billingVersion}
          price="Free"
          action={
            <Button
              disabled={plan === 'community' || plan === 'enterprise' || billingReadonly}
              variant="outline"
              onClick={() => {
                if (currentPlanPrice && communityPrice && isDowngradeFromYearly(currentPlanPrice, communityPrice)) {
                  showYearlyDowngradeDialog(openCustomerPortal)
                  return
                }
                setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 1)
                setProductQuantity(TEAM_PLAN_PRODUCT_ID, 0)
                setProductQuantity(PLUS_PLAN_PRODUCT_ID, 0)
                void showConfirmationPrompt(
                  <Text>
                    After updating to the Pay-as-you-go plan, your new monthly bill will be
                    <Text weight={'bold'}> ${computeTotalCost(productQuantityMap, promoCode)}</Text> per month. Are you
                    sure you want to proceed?
                  </Text>,
                  {
                    confirmLabel: 'Update plan',
                    title: 'Do you want to proceed?',
                  }
                ).then(() => {
                  handleMonthlyCheckout()
                })
              }}
            >
              {plan === 'community' ? 'Current plan' : 'Update plan'}
            </Button>
          }
        />
        {!isLegacyPricing && (
          <PlanCard
            color={plan !== 'enterprise' ? 'indigo' : undefined}
            plan="plus"
            billingVersion={props.billingVersion}
            price={
              <Flex gap={'3'} align={'baseline'}>
                <Flex gap={'1'} align={'baseline'} direction={'column'}>
                  <Flex gap={'2'} align={'center'}>
                    <Text>{discountedPlusPlanPrice ?? plusPricePerMonth}</Text>
                    {isYearly && <Badge color="orange">-10%</Badge>}
                  </Flex>
                  <Text size={'2'} color="gray" weight={'regular'}>
                    per month{isYearly && `, $${plusPricePerYear} billed annually`}
                  </Text>
                </Flex>
                {discountedPlusPlanPrice && (
                  <>
                    <Text size={'2'} color="gray" weight={'regular'} className="line-through">
                      {plusPricePerMonth}/month
                    </Text>
                  </>
                )}
              </Flex>
            }
            action={
              <Button
                disabled={isPlusButtonDisabled}
                onClick={() => {
                  if (currentPlanPrice && plusPrice && isDowngradeFromYearly(currentPlanPrice, plusPrice)) {
                    showYearlyDowngradeDialog(openCustomerPortal)
                    return
                  }

                  if (isYearly) {
                    handleYearlyCheckout({
                      ...productQuantityMap[PLUS_PLAN_PRODUCT_ID]!,
                      priceId: plusPrice?.id ?? '',
                      quantity: 1,
                    })
                  }

                  if (!isYearly) {
                    setProductQuantity(PLUS_PLAN_PRODUCT_ID, 1)
                    setProductQuantity(TEAM_PLAN_PRODUCT_ID, 0)
                    setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 0)
                    void showConfirmationPrompt(
                      <Text>
                        After switching to the Plus plan, your new monthly bill will be
                        <Text weight={'bold'}> ${dollars(computeTotalCost(productQuantityMap, promoCode))}</Text> per
                        month. Are you sure you want to proceed?
                      </Text>,
                      {
                        confirmLabel: 'Switch to Plus plan',
                        title: 'Do you want to proceed?',
                      }
                    ).then(() => {
                      handleMonthlyCheckout()
                    })
                  }
                }}
              >
                {isPlusButtonDisabled ? 'Current plan' : 'Buy plan'}
              </Button>
            }
          />
        )}

        <PlanCard
          color={plan !== 'enterprise' ? 'green' : undefined}
          plan="team"
          billingVersion={props.billingVersion}
          badge={plan !== 'enterprise' && isYearly ? 'Best value' : undefined}
          price={
            <Flex gap={'3'} align={'baseline'}>
              <Flex gap={'1'} align={'baseline'} direction={'column'}>
                <Flex gap={'2'} align={'center'}>
                  <Text>{discountedTeamPlanPrice ? discountedTeamPlanPrice : teamPricePerMonth}</Text>
                  {isYearly && <Badge color="orange">-10%</Badge>}
                </Flex>
                <Text size={'2'} color="gray" weight={'regular'}>
                  per month{isYearly && `, $${teamPricePerYear} billed annually`}
                </Text>
              </Flex>
              {discountedTeamPlanPrice && (
                <>
                  <Text size={'2'} color="gray" weight={'regular'} className="line-through">
                    {teamPricePerMonth}/month
                  </Text>
                </>
              )}
            </Flex>
          }
          action={
            <Button
              disabled={isTeamButtonDisabled}
              onClick={() => {
                if (currentPlanPrice && teamPrice && isDowngradeFromYearly(currentPlanPrice, teamPrice)) {
                  showYearlyDowngradeDialog(openCustomerPortal)
                  return
                }

                if (isYearly) {
                  handleYearlyCheckout({
                    ...productQuantityMap[TEAM_PLAN_PRODUCT_ID]!,
                    priceId: teamPrice?.id ?? '',
                    quantity: 1,
                  })
                }

                if (!isYearly) {
                  setProductQuantity(TEAM_PLAN_PRODUCT_ID, 1)
                  setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 0)
                  setProductQuantity(PLUS_PLAN_PRODUCT_ID, 0)
                  void showConfirmationPrompt(
                    <Text>
                      After upgrading to the Team plan, your new monthly bill will be
                      <Text weight={'bold'}> ${dollars(computeTotalCost(productQuantityMap, promoCode))}</Text> per
                      month. Are you sure you want to proceed?
                    </Text>,
                    {
                      confirmLabel: 'Upgrade to Team plan',
                      title: 'Do you want to proceed?',
                    }
                  ).then(() => {
                    handleMonthlyCheckout()
                  })
                }
              }}
            >
              {isTeamButtonDisabled ? 'Current plan' : 'Buy plan'}
            </Button>
          }
        />
        <PlanCard
          plan="enterprise"
          billingVersion={props.billingVersion}
          color={plan === 'enterprise' ? 'iris' : undefined}
          badge={plan === 'enterprise' ? 'Current plan' : undefined}
          price="Custom"
          action={
            <a href="https://botpress.com/contact-us" target="_blank" rel="noreferrer">
              <Button className="w-full" variant="outline">
                Contact us
              </Button>
            </a>
          }
        />
      </Grid>
    </Flex>
  )
}

type PlanCardProps = {
  color?: Color
  plan: ClientReturn<'getWorkspace'>['plan']
  badge?: ReactNode
  price?: ReactNode
  action: ReactNode
  billingVersion: BillingVersion
}

const PlanCard = ({ color, action, price, plan, badge, billingVersion }: PlanCardProps) => {
  return (
    <Card data-accent-color={color ?? ThemeColor} className={cn('p-7', { 'border-2 border-accent-6': color })}>
      <Flex direction={'column'} gap={'4'}>
        <Flex justify={'between'}>
          <Text weight={'medium'} className={cn({ 'text-accent-11': color })}>
            {billingPlan[plan].name}
          </Text>
          {badge && <Badge>{badge}</Badge>}
        </Flex>
        <Text size={'1'} color="gray" className="h-14 xl:h-8">
          {billingPlan[plan].description}
        </Text>
        <Text size={'5'} weight={'bold'}>
          {price}
        </Text>
        {action}
        <PlanInclusions plan={plan} billingVersion={billingVersion} />
      </Flex>
    </Card>
  )
}
