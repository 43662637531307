import { Outlet, createFileRoute } from '@tanstack/react-router'
import { useCurrentRouteId } from '../../../hooks'
import type { ComponentProps } from 'react'
import { Page } from '~/componentsV2'

export const Route = createFileRoute('/workspaces/$workspaceId/billing')({
  component: Component,
})

function Component() {
  const currentRouteId = useCurrentRouteId() ?? ''

  const items: ComponentProps<typeof Page>['navbarItems'] = [
    {
      children: 'Summary',
      to: '/workspaces/$workspaceId/billing/summary',
      from: currentRouteId,
    },
    {
      children: 'Add-ons',
      to: '/workspaces/$workspaceId/billing/add-ons',
      from: currentRouteId,
    },
    {
      children: 'Plans',
      to: '/workspaces/$workspaceId/billing/plans',
      from: currentRouteId,
    },
  ]

  const itemsWithActiveState = items.map((i) => ({
    ...i,
    active: currentRouteId.includes(i?.to ?? 'not-a-path'),
  }))

  return (
    <Page title="Billing" navbarItems={itemsWithActiveState}>
      <Outlet />
    </Page>
  )
}
