import { create, type StoreApi, type UseBoundStore } from 'zustand'
import { blue } from '@radix-ui/colors'
import { webchatConfigSchema, webchatConfigToWebchatIntegrationInput, type WebchatConfig } from '@bpinternal/shared'
import { createJSONStorage, persist } from 'zustand/middleware'
import { getApiClientForBot } from '../../../client'
import axios from 'axios'
import { queryClient } from '../../../providers/ReactQuery'
import { getQueryOptions } from '../../../services'
import { getIntegrationsFromBot, updateBot } from '../../bots/services'
import * as v from 'valibot'
import { showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'

type WebchatConfigState = WebchatConfig & {
  _hasHydrated: boolean
  _updating: boolean
  customStyleSheet?: string
  allowedOrigins?: string[]
  webhookId?: string
}

type WebchatConfigActions = {
  setBotName: (botName?: string) => void
  setBotAvatar: (botAvatar?: string) => void
  setBotDescription: (botDescription?: string) => void
  setComposerPlaceholder: (composerPlaceholder?: string) => void
  setShowPoweredBy: (showPoweredBy?: boolean) => void
  setAllowFileUpload: (allowFileUpload?: boolean) => void
  setEmail: (email?: { link: string; title: string }) => void
  setPhone: (phone?: { link: string; title: string }) => void
  setPrivacyPolicy: (privacyPolicy?: { link: string; title: string }) => void
  setTermsOfService: (termsOfService?: { link: string; title: string }) => void
  setWebsite: (website?: { link: string; title: string }) => void
  setColor: (color?: string) => void
  setThemeMode: (themeMode?: 'light' | 'dark') => void
  setVariant: (variant?: 'soft' | 'solid') => void
  setRadius: (radius?: number) => void
  setFontFamily: (fontFamily?: 'rubik' | 'inter' | 'ibm' | 'fira') => void
  setCustomStyleSheet: (customStyleSheet?: string) => void
  setAllowedOrigins: (allowedOrigins?: string[]) => void
  saveConfig: () => Promise<void>
  _save: () => Promise<void>

  setHasHydrated: (hasHydrated: boolean) => void
}

const defaultThemeValues: Pick<WebchatConfig, 'color' | 'themeMode' | 'radius' | 'variant' | 'fontFamily'> = {
  color: blue.blue8,
  themeMode: 'light',
  radius: 1,
  variant: 'soft',
  fontFamily: 'inter',
}

const storeInstances: Record<string, UseBoundStore<StoreApi<WebchatConfigActions & WebchatConfigState>>> = {}

export const getUseConfigStore = ({ botId, workspaceId }: { botId: string; workspaceId: string }) => {
  const client = getApiClientForBot({ botId, workspaceId })
  if (!storeInstances[botId]) {
    storeInstances[botId] = create<WebchatConfigActions & WebchatConfigState>()(
      persist(
        (set, get) => ({
          ...defaultThemeValues,
          _hasHydrated: false,
          _updating: false,
          setAllowFileUpload: (allowFileUpload) => set({ allowFileUpload }),
          setBotAvatar: (botAvatar) => set({ botAvatar }),
          setBotDescription: (botDescription) => set({ botDescription }),
          setBotName: (botName) => set({ botName }),
          setComposerPlaceholder: (composerPlaceholder) => set({ composerPlaceholder }),
          setEmail: (email) => set({ email }),
          setPhone: (phone) => set({ phone }),
          setPrivacyPolicy: (privacyPolicy) => set({ privacyPolicy }),
          setTermsOfService: (termsOfService) => set({ termsOfService }),
          setWebsite: (website) => set({ website }),
          setShowPoweredBy: (showPoweredBy) => set({ showPoweredBy }),
          setColor: (color) => set({ color }),
          setThemeMode: (themeMode) => set({ themeMode }),
          setVariant: (variant) => set({ variant }),
          setRadius: (radius) => set({ radius }),
          setFontFamily: (fontFamily) => set({ fontFamily }),
          setCustomStyleSheet: (customStyleSheet) => set({ customStyleSheet }),
          setAllowedOrigins: (allowedOrigins) => set({ allowedOrigins }),
          setHasHydrated: (hasHydrated) => set({ _hasHydrated: hasHydrated }),
          saveConfig: async () => {
            if (!get()._hasHydrated || get()._updating) {
              return
            }
            set({ _updating: true })
            try {
              await get()._save()
              showSuccessToast('Webchat configuration saved successfully')
            } catch (error) {
              showErrorToast('Failed to save webchat configuration')
            } finally {
              set({ _updating: false })
            }
          },
          _save: async () => {
            const currentState = get()

            const botQueryOptions = getQueryOptions('workspaces_/$workspaceId_/bots_/$botId_', {
              botId,
              workspaceId,
            })
            const bot = await queryClient.fetchQuery(botQueryOptions)
            const webchatIntegration = getIntegrationsFromBot(bot).find((bi) => bi.name === 'webchat')

            if (!webchatIntegration) {
              return
            }

            const { customStyleSheet, allowedOrigins, ...state } = currentState

            let additionalStylesheetUrl: string | undefined
            if (customStyleSheet) {
              const {
                file: { url },
              } = await getApiClientForBot({ botId, workspaceId }).uploadFile({
                key: 'webchatAdditionalStyles.css',
                content: customStyleSheet,
                accessPolicies: ['public_content'],
                contentType: 'text/css',
                tags: {
                  webchatAdditionalStylesVersion: '1',
                  source: 'integration',
                  integrationName: 'webchat',
                  system: 'true',
                },
                publicContentImmediatelyAccessible: true,
              })
              additionalStylesheetUrl = url
            }
            const configResult = v.safeParse(webchatConfigToWebchatIntegrationInput, {
              ...state,
              additionalStylesheetUrl,
            })
            const configuration = configResult.success ? configResult.output : undefined

            await updateBot({
              workspaceId,
              id: botId,
              integrations: {
                [webchatIntegration.id]: {
                  enabled: webchatIntegration.enabled,
                  configuration: { ...configuration, allowedOrigins },
                },
              },
            }).catch(() => {
              console.log('failed to update bot') // handle this error
            })
          },
        }),
        {
          name: botId,
          onRehydrateStorage: (state) => () => state.setHasHydrated(true),
          storage: createJSONStorage(() => ({
            getItem: async () => {
              // Implement your custom get logic here
              const [configFile] = await client.list
                .files({
                  tags: { webchatConfigVersion: 1, integrationName: 'webchat', source: 'integration' },
                })
                .collect()

              if (!configFile) {
                return '{}'
              }

              const { data } = await axios.get<{ configuration: WebchatConfig } | undefined>(configFile.url, {
                withCredentials: false,
              })

              const parsedConfig = v.safeParse(webchatConfigSchema, data)

              const botQueryOptions = getQueryOptions('workspaces_/$workspaceId_/bots_/$botId_', {
                botId,
                workspaceId,
              })
              const bot = await queryClient.fetchQuery(botQueryOptions)
              const webchatIntegration = getIntegrationsFromBot(bot).find((bi) => bi.name === 'webchat')

              const { allowedOrigins } = webchatIntegration?.configuration ?? {}
              const webhookId = webchatIntegration?.webhookId

              if (!parsedConfig.success) {
                return JSON.stringify({ state: { ...(allowedOrigins ? { allowedOrigins } : {}) }, version: 0 })
              }

              const {
                output: {
                  configuration: { additionalStylesheetUrl, ...state },
                },
              } = parsedConfig

              const { data: customStyleSheet } = additionalStylesheetUrl
                ? await axios.get(additionalStylesheetUrl, {
                    withCredentials: false,
                  })
                : { data: undefined }

              return JSON.stringify({
                state: { ...state, ...(allowedOrigins ? { allowedOrigins } : {}), customStyleSheet, webhookId },
                version: 0,
              })
            },
            setItem: () => {
              // We don't want to auto save. We want to save only when the user clicks on save
            },
            removeItem: () => {
              // In our case we never want to delete the webchat config fite
            },
          })),
        }
      )
    )
  }
  return storeInstances[botId]
}
