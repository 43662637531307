import { createFileRoute, Navigate } from '@tanstack/react-router'
import { z } from 'zod'
import { Logs } from '~/components'

const today = new Date().toISOString()
const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()

const LogsSearchOptions = z.object({
  startDate: z
    .string()
    .datetime()
    .optional()
    .catch(() => yesterday),
  endDate: z
    .string()
    .datetime()
    .optional()
    .catch(() => today),
  conversationId: z
    .string()
    .min(1)
    .optional()
    .catch(() => undefined),
  userId: z
    .string()
    .min(1)
    .optional()
    .catch(() => undefined),
  level: z
    .union([z.literal('INFO'), z.literal('ERROR'), z.literal('WARN'), z.literal('DEBUG')])
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId/logs')({
  validateSearch: LogsSearchOptions,
  component: Component,
})

function Component() {
  const { workspaceId, integrationId } = Route.useParams()
  const { conversationId, level, userId, endDate, startDate } = Route.useSearch()
  const navigate = Route.useNavigate()

  if (!startDate || !endDate) {
    return (
      <Navigate
        from={Route.fullPath}
        to={Route.fullPath}
        replace
        search={(prev) => ({ ...prev, startDate: startDate || yesterday, endDate: endDate || today })}
      />
    )
  }

  return (
    <div className="relative h-[calc(100vh-14rem)]">
      <Logs
        id={integrationId}
        type="integration"
        workspaceId={workspaceId}
        startDate={new Date(startDate)}
        setStartDate={(d: Date) =>
          navigate({
            from: Route.fullPath,
            to: Route.fullPath,
            search: (prev) => ({ ...prev, startDate: d.toISOString() }),
          })
        }
        endDate={new Date(endDate)}
        setEndDate={(d: Date) =>
          navigate({
            from: Route.fullPath,
            to: Route.fullPath,
            search: (prev) => ({ ...prev, endDate: d.toISOString() }),
          })
        }
        conversationId={conversationId}
        level={level}
        userId={userId}
      />
    </div>
  )
}
