import { Portal } from '@radix-ui/themes'
import { usePortal } from '../providers'

type Props = {
  children?: React.ReactNode
}
export const BannerPortal = (props: Props) => {
  const { bannerPortalRef } = usePortal()
  return <Portal className="hidden flex-col gap-2 has-[*]:flex" {...props} container={bannerPortalRef} />
}
