import { ClientReturn } from 'botpress-client'
import { getApiClient, getGenericClient } from '../../../client'
import { config, httpClient } from '../../../shared'
import { listAll } from '../../../utils'
import { BotV2Partial } from '../../bots/types'
import { Role, WorkspaceMember } from '../../users/types'
import { Workspace, WorkspaceSummary } from '../types'
import { BillingApiClient } from '../../../features/billing/services'

const CDM_BACKEND_BASE_URL = config.cdmBackendBaseUrl

export async function createWorkspace(name: string): Promise<Workspace[]> {
  const workspaces = await httpClient.callApi<Workspace[]>({
    baseURL: CDM_BACKEND_BASE_URL,
    url: '/v1/workspaces',
    method: 'POST',
    data: { name },
  })

  for (const workspace of workspaces) {
    workspace['botsV2'] = await getV2Bots(workspace.id)
  }

  return workspaces
}

export async function getAuditsByWorkspaceId(workspaceId: string) {
  const apiClient = getApiClient(workspaceId)

  return listAll(
    (params) => apiClient.getAuditRecords({ ...params, id: workspaceId }),
    {},
    ({ records }) => records
  )
}

export async function getV2Bots(workspaceId: string) {
  return listAll(getApiClient(workspaceId).listBots, {}, ({ bots }) =>
    bots.map(
      (bot) =>
        <BotV2Partial>{
          ...bot,
          type: 'V2',
        }
    )
  )
}

export async function listWorkspaces(): Promise<WorkspaceSummary[]> {
  return listAll(getGenericClient().listWorkspaces, {}, ({ workspaces }) => workspaces)
}

export async function listWorkspaceMembers(
  workspaceId: string
): Promise<ClientReturn<'listWorkspaceMembers'>['members']> {
  return listAll(getApiClient(workspaceId).listWorkspaceMembers, {}, ({ members }) => members)
}

export async function getSubscription(workspaceId: string) {
  return await BillingApiClient.getSubscription(workspaceId)
}

export async function getSubscriptions(workspaceId: string) {
  return await BillingApiClient.getSubscriptions(workspaceId)
}

export async function getSubscriptionSchedule(workspaceId: string) {
  return await BillingApiClient.getSubscriptionSchedule(workspaceId)
}

export async function listWorkspaceIntegrations(workspaceId: string) {
  const version = '0.0.1' // TODO: get all workspace integrations and then group them by name
  return listAll(getApiClient(workspaceId).listIntegrations, { version }, ({ integrations }) => integrations)
}

export async function getWorkspaces(): Promise<Workspace[]> {
  const workspaces = await httpClient.callApi<Workspace[]>({
    baseURL: CDM_BACKEND_BASE_URL,
    url: '/v1/workspaces',
    method: 'GET',
  })

  if (workspaces) {
    for (const workspace of workspaces) {
      workspace['botsV2'] = await getV2Bots(workspace.id)
    }
  }

  return workspaces
}

export async function addWorkspaceMember(workspaceId: string, email: string, role: Role): Promise<WorkspaceMember> {
  return await httpClient.callApi<WorkspaceMember>({
    baseURL: CDM_BACKEND_BASE_URL,
    url: `/v1/workspaces/${workspaceId}/members`,
    method: 'POST',
    data: { email: email.trim(), role },
  })
}

export async function updateWorkspaceMember(
  workspaceId: string,
  workspaceMemberId: string,
  newValues: { role: Role }
): Promise<WorkspaceMember> {
  return await httpClient.callApi<WorkspaceMember>({
    baseURL: CDM_BACKEND_BASE_URL,
    url: `/v1/workspaces/${workspaceId}/member/${workspaceMemberId}`,
    method: 'PUT',
    data: newValues,
  })
}

export async function upgradePlan(workspaceId: string): Promise<void> {
  return await httpClient.callApi({
    baseURL: CDM_BACKEND_BASE_URL,
    url: `/v1/workspaces/${workspaceId}/upgrade`,
    method: 'POST',
  })
}

export async function downgradePlan(workspaceId: string): Promise<void> {
  return await httpClient.callApi<void>({
    baseURL: CDM_BACKEND_BASE_URL,
    url: `/v1/workspaces/${workspaceId}/downgrade`,
    method: 'POST',
  })
}

export async function closeWorkspace(workspaceId: string): Promise<void> {
  return await httpClient.callApi<void>({
    baseURL: CDM_BACKEND_BASE_URL,
    url: `/v1/workspaces/${workspaceId}/close`,
    method: 'POST',
  })
}
