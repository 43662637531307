import { useEffect, useState } from 'react'
import Stripe from 'stripe'
import { useCurrentUser } from '../../hooks'
import { useBillingProducts, useBillingSubscription, useBillingSubscriptions } from '../../hooks/billing'
import { config } from '../../shared/configurations'
import { BillingApiClient } from '../billing/services'
import { usePromoCode } from '~/hooks/billing/usePromoCode'
import { useBillingInfo } from '~/hooks/billing/useBillingInto'
import { useBillingSubscriptionSchedule } from '~/hooks/billing/useBillingSubscriptionSchedule'
import { useSuspenseQuery } from '@tanstack/react-query'
import { getQueryOptions } from '~/services'
import { ClientOutputs } from 'botpress-client'
import { isArrayEqual } from '~/utils/arrays'
import _ from 'lodash'

export const COMMUNITY_PLAN_PRODUCT_ID = config.stripeProductIdCommunityPlan
export const TEAM_PLAN_PRODUCT_ID = config.stripeProductIdTeamPlan
export const PLUS_PLAN_PRODUCT_ID = config.stripeProductIdPlusPlan
export type ProductWithPrices = Stripe.Product & { price: Stripe.Price; prices: Stripe.Price[] }
export type ProductQuantityMap = Record<
  string,
  { quantity: number; priceId: string; subscriptionLineItemId?: string; productDetails: ProductWithPrices }
>
export type PlanAddOn = {
  productId: string
  name: string
  quantity: number
}
export type Plan = ClientOutputs['getWorkspace']['plan']
export type BillingVersion = ClientOutputs['getWorkspace']['billingVersion']

//TODO: Refactor this to use suspense once new dashboard is GA
export function useBilling(workspaceId: string) {
  const [productQuantityMap, setProductQuantityMap] = useState<ProductQuantityMap>({})
  const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean>(false)
  const [initialProductQuantityMap, setInitialProductQuantityMap] = useState<ProductQuantityMap>({})
  const [activePlanId, setActivePlanId] = useState<string>(COMMUNITY_PLAN_PRODUCT_ID)
  const [initialActivePlanId, setInitialActivePlanId] = useState<string | undefined>(COMMUNITY_PLAN_PRODUCT_ID)
  const [currentMonthAddOns, setCurrentMonthAddOns] = useState<PlanAddOn[]>([])
  const [nextMonthAddOns, setNextMonthAddOns] = useState<PlanAddOn[]>([])
  const [nextMonthPlan, setNextMonthPlan] = useState<Plan>('community')
  const user = useCurrentUser()
  const billingInfo = useBillingInfo(workspaceId)

  function updateProductQuantity(productId: string, quantityDelta: number) {
    const newProductQuantityMap: typeof productQuantityMap = { ...productQuantityMap }
    if (!newProductQuantityMap[productId]) {
      return
    }
    const currentQuantity = newProductQuantityMap[productId]?.quantity ?? 0
    newProductQuantityMap[productId]!.quantity = Math.max(currentQuantity + quantityDelta, 0)
    setProductQuantityMap(newProductQuantityMap)
  }

  function setProductQuantity(productId: string, quantity: number) {
    const newProductQuantityMap: typeof productQuantityMap = { ...productQuantityMap }
    if (!newProductQuantityMap[productId]) {
      return
    }
    newProductQuantityMap[productId]!.quantity = Math.max(quantity, 0)

    setProductQuantityMap(newProductQuantityMap)
  }

  const { products } = useBillingProducts(workspaceId)
  const { subscription, isFetching: isLoadingSubscription } = useBillingSubscription(workspaceId)
  const { subscriptions, isFetching: isLoadingSubscriptions } = useBillingSubscriptions(workspaceId)
  const { subscriptionSchedule, isFetching: isLoadingSubscriptionSchedule } =
    useBillingSubscriptionSchedule(workspaceId)
  const { promoCode } = usePromoCode(workspaceId)

  const findProductById = (productId: string) => products?.find((product) => product.id === productId)
  const findProductByPriceId = (priceId: string) => products?.find((product) => product.price.id === priceId)

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getNextMonthPhaseItems = (subscriptionSchedule: Stripe.SubscriptionSchedule) => {
    return subscriptionSchedule.phases[1]?.items ?? []
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const computeCurrentMonthAddOns = (subscription: Stripe.Subscription): PlanAddOn[] => {
    return computePlanAddons(
      subscription.items.data.map((item) => ({
        productId: item.price.product.toString(),
        quantity: item.quantity ?? 0,
      }))
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const computeNextMonthAddOns = (subscriptionSchedule: Stripe.SubscriptionSchedule): PlanAddOn[] => {
    const subscriptionItems = getNextMonthPhaseItems(subscriptionSchedule)

    return computePlanAddons(
      subscriptionItems.reduce((acc, item) => {
        const product = findProductByPriceId(item.price.toString())
        if (product) {
          acc.push({ productId: product.id, quantity: item.quantity ?? 0, name: product.name })
        }
        return acc
      }, [] as PlanAddOn[])
    )
  }

  const computePlanAddons = (subscriptionItems: { productId: string; quantity: number }[]): PlanAddOn[] => {
    return subscriptionItems
      .filter(
        ({ productId, quantity }) =>
          productId !== TEAM_PLAN_PRODUCT_ID &&
          productId !== COMMUNITY_PLAN_PRODUCT_ID &&
          productId !== PLUS_PLAN_PRODUCT_ID &&
          quantity > 0
      )
      .reduce((acc, item) => {
        const product = findProductById(item.productId)
        if (product) {
          acc.push({ productId: product.id, quantity: item.quantity ?? 0, name: product.name })
        }
        return acc
      }, [] as PlanAddOn[])
  }

  const computeNextMonthPlan = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    subscriptionSchedule: Stripe.SubscriptionSchedule,
    currentPlan: Plan
  ): Plan | undefined => {
    const subscriptionItems = getNextMonthPhaseItems(subscriptionSchedule)
    const hasPlan = (productId: string) =>
      subscriptionItems.some(
        (item) => findProductByPriceId(item.price.toString())?.id === productId && (item.quantity ?? 0) > 0
      )

    const hasTeamPlan = hasPlan(TEAM_PLAN_PRODUCT_ID)
    if (hasTeamPlan) {
      return 'team'
    }
    if (hasPlan(PLUS_PLAN_PRODUCT_ID)) {
      return 'plus'
    }
    if (currentPlan === 'enterprise') {
      return 'enterprise'
    }
    return 'community'
  }

  // Avoid using cache since we can't invalidate it when customer changes plan in stripe customer portal
  const { plan, billingVersion } = useSuspenseQuery({
    ...getQueryOptions('workspaces_/$workspaceId_', { workspaceId }),
    queryKey: [],
    gcTime: 0,
    staleTime: 0,
  }).data

  useEffect(() => {
    if (products && products.length > 0) {
      const productIdSubscriptionItemMap: Record<string, Stripe.SubscriptionItem> = subscription
        ? subscription.items.data.reduce(
            (acc, item) => {
              acc[item.price.product.toString()] = item
              return acc
            },
            {} as Record<string, Stripe.SubscriptionItem>
          )
        : {}

      const _productQuantityMap: typeof productQuantityMap = {}
      for (const product of products) {
        const productId = product.id.toString()
        const productInSubscription = productIdSubscriptionItemMap[productId]
        if (!productInSubscription) {
          _productQuantityMap[productId] = {
            quantity: 0,
            priceId: product.price.id,
            productDetails: product,
          }
        } else {
          _productQuantityMap[productId] = {
            productDetails: product,
            quantity: productInSubscription.quantity || 0,
            priceId: product.price.id,
            subscriptionLineItemId: productInSubscription?.id,
          }
          if (
            (productInSubscription?.price.product === TEAM_PLAN_PRODUCT_ID ||
              productInSubscription?.price.product === COMMUNITY_PLAN_PRODUCT_ID ||
              productInSubscription?.price.product === PLUS_PLAN_PRODUCT_ID) &&
            Boolean(productInSubscription?.quantity)
          ) {
            setActivePlanId(productId)
            setInitialActivePlanId(productId)
          }
        }
      }

      // Subscription will always have items (metered usage items) if it exists (even if their quantity is 0)
      if (subscription) {
        setHasActiveSubscription(Boolean(subscription.items.data.length))
      }
      setInitialProductQuantityMap(structuredClone(_productQuantityMap))
      setProductQuantityMap(_productQuantityMap)

      setCurrentMonthAddOns(subscription ? computeCurrentMonthAddOns(subscription) : [])
      setNextMonthAddOns(
        subscriptionSchedule
          ? computeNextMonthAddOns(subscriptionSchedule)
          : subscription
            ? // upgrades
              computeCurrentMonthAddOns(subscription)
            : []
      )
      if (subscriptionSchedule) {
        setNextMonthPlan(computeNextMonthPlan(subscriptionSchedule, plan) ?? plan)
      } else {
        setNextMonthPlan(plan)
      }
    }
  }, [subscription, products, subscriptionSchedule, plan])

  async function generateCheckoutSession(_productQuantityMap: ProductQuantityMap) {
    return BillingApiClient.generateCheckoutSession(workspaceId, _productQuantityMap, user.email)
  }

  const nextMonthPlanChanged =
    nextMonthPlan !== plan ||
    !isArrayEqual(_.sortBy(currentMonthAddOns, ['productId']), _.sortBy(nextMonthAddOns, ['productId']))
  const nextMonth = nextMonthPlanChanged ? { plan: nextMonthPlan, addons: nextMonthAddOns } : undefined

  return {
    hasActiveSubscription,
    initialProductQuantityMap,
    initialActivePlanId,
    activePlanId,
    subscription,
    subscriptions,
    subscriptionSchedule,
    productQuantityMap,
    setActivePlanId,
    updateProductQuantity,
    setProductQuantity,
    generateCheckoutSession,
    isLoading: isLoadingSubscription || isLoadingSubscriptionSchedule || isLoadingSubscriptions,
    products,
    promoCode,
    billingInfo,
    currentMonthAddOns,
    plan,
    nextMonth,
    billingVersion,
  }
}
