import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import Stripe from 'stripe'
import { getQueryKey, queryFunctions } from '~/services'

export function useBillingSubscriptions(workspaceId: string) {
  const { data, ...rest } = useQuery({
    enabled: Boolean(workspaceId),
    queryKey: getQueryKey('workspaces_/$workspaceId_/subscriptions', { workspaceId }),
    queryFn: async () => queryFunctions['workspaces_/$workspaceId_/subscriptions']({ workspaceId }),
  })

  const subscriptions: Stripe.Subscription[] | undefined =
    data && Array.isArray(data) && data.length > 0 ? (data as Stripe.Subscription[]) : undefined

  return { subscriptions, ...rest }
}
