import { PartyPopper } from 'lucide-react'
import type { BillingVersion, Plan } from '~/features/usage/useBilling'
import capitalize from 'lodash/capitalize'
import { Text, Flex, Link, Card, Box } from '@radix-ui/themes'
import { Button } from '@bpinternal/ui-kit'
import { Link as RouterLink } from '@tanstack/react-router'
import { PlanInclusions } from './PlanInclusions'

type WelcomePageProps = {
  plan: Extract<Plan, 'plus' | 'team'>
  billingVersion: BillingVersion
}

const TeamMessage = () => {
  return (
    <Flex>
      <Text size={'2'}>
        Your dedicated Customer Success Manager will reach out shortly to schedule an onboarding session.Have questions
        in the meantime?{' '}
        <Link color="blue" href="https://www.youtube.com/watch?v=iiQs1B9qCIE">
          Here’s how to access live-chat support.
        </Link>
      </Text>
    </Flex>
  )
}

const PlusMessage = () => {
  return (
    <Text size={'2'}>
      It&apos;s all about keeping your building experience simple, efficient, and feature-packed: exactly what you need
      to build and ship AI agents. Fast.
    </Text>
  )
}

export const WelcomePage = ({ plan, billingVersion }: WelcomePageProps) => {
  return (
    <Flex width={'100%'} height={'100vh'} justify={'center'} align={'center'} className="bg-gray-2">
      <Card className="flex max-w-[874px] gap-8 p-10">
        <Flex direction={'column'} flexBasis={'50%'} gap={'7'} align={'start'}>
          <PartyPopper strokeWidth={'1'} />

          <Flex direction={'column'}>
            <Text size={'8'} weight={'medium'}>
              Welcome to
            </Text>
            <Text size={'8'} weight={'medium'}>
              Botpress {capitalize(plan)}.
            </Text>
          </Flex>

          {plan === 'team' && <TeamMessage />}
          {plan === 'plus' && <PlusMessage />}

          <RouterLink to="/" preload="viewport">
            <Button
              className="px-6"
              color="indigo"
              leading={<img className="w-[12px] brightness-0 invert filter" src="/bp-logo.svg" />}
            >
              Start Building
            </Button>
          </RouterLink>
        </Flex>

        <Box flexBasis={'50%'}>
          <PlanInclusions plan={plan} billingVersion={billingVersion} />
        </Box>
      </Card>
    </Flex>
  )
}
