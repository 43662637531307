import { AnalyticsBrowser } from '@segment/analytics-next'
import { type ClientReturn, Integration } from 'botpress-client'
import { ReactNode, useEffect } from 'react'
import { User } from '../features/users/types'
import { useAppStore } from '../stores'
import { useAsync } from 'react-use'
import { config } from '../shared'
import { Plan } from '~/features/usage/useBilling'

export type AnalyticEvent =
  | {
      type: 'hub_search'
      query: string
      nResults: number
    }
  | {
      type: 'integration_installed' | 'integration_viewed'
      integration: Partial<Integration>
    }
  | {
      type: 'navigate_to_bot'
      from?: 'recent_bots' | 'all_bots'
    }
  | { type: 'update_configuration_variables' }
  | { type: 'onboarding_survey_presented' }
  | { type: 'onboarding_survey_completed' }
  | { type: 'create_bot'; botId: string }
  | { type: 'open_bot_after_creation'; botId: string }
  | {
      type: 'update_workspace_profile'
      workspaceId: string
      public: boolean
      socialAccounts: number
      about: boolean
      email: boolean
      website: boolean
      profilePicture: boolean
    }
  | { type: 'update_handle'; workspaceId: string }
  | {
      type: 'update_addons'
      state: 'clicked' | 'confirmed'
      upgrade: boolean
      workspaceId: string
      plan: ClientReturn<'getWorkspace'>['plan']
    }
  | {
      type: 'update_ai_spend'
      state: 'clicked' | 'confirmed' | 'credit_card_required' | 'clicked_add_card'
      workspaceId: string
      prevAiSpend: number
      newAiSpend: number
      plan: ClientReturn<'getWorkspace'>['plan']
    }
  | { type: 'analytics_date_picker_shortcut'; shortcut: string; workspaceId: string; botId: string }
  | { type: 'analytics_date_picker_submit'; workspaceId: string; botId: string }
  | {
      type: 'select_conversation'
      conversationId: string
      workspaceId: string
      botId: string
      integration: string
      channel: string
      tags: number
    }
  | {
      type: 'error_event'
      routeId?: string
      botId?: string
      workspaceId?: string
      conversationId?: string
      integrationId?: string
      userId?: string
      userEmail?: string
    }
  | {
      type: 'paywall_triggered'
      feature: string
      fromPlan: Plan
      workspaceId: string
    }
  | {
      type: 'request_copy_to_bot_from_dashboard'
      sourceBotId: string
      targetBotId: string
    }
  | {
      type: 'copy_to_bot_success_from_dashboard'
      sourceBotId: string
      targetBotId: string
    }
  | {
      type: 'copy_to_bot_error_from_dashboard'
      sourceBotId: string
      targetBotId: string
      error: string
    }

type PageEvent = {
  name: string
  botId?: string
  workspaceId?: string
  conversationId?: string
  integrationId?: string
}

type Props = {
  children: ReactNode
}
export const SegmentProvider = ({ children }: Props) => {
  const currentUser = useAppStore((state) => state.currentUser)

  useEffect(() => {
    const { segmentAnalyticsWriteKey } = config
    if (segmentAnalyticsWriteKey) {
      analytics.load({ writeKey: segmentAnalyticsWriteKey })
    }
  }, [])

  useAsync(async () => {
    if (currentUser) {
      await identifyUser(currentUser)
    }
  }, [currentUser])

  return <>{children}</>
}

const analytics = new AnalyticsBrowser()

export function trackEvent(event: AnalyticEvent, callback?: Parameters<AnalyticsBrowser['track']>[2]) {
  const { type, ...data } = event
  void analytics.track(type, data, callback).catch((error: any) => {
    console.warn('unable to track event', error)
  })
}

export function trackPage(event: PageEvent, callback?: Parameters<AnalyticsBrowser['page']>[2]) {
  const { name, ...props } = event
  void analytics.page(name, props, callback).catch((error: any) => {
    console.warn('unable to track page', error)
  })
}

async function identifyUser(
  { id, email }: Pick<User, 'email' | 'id'>,
  callback?: Parameters<AnalyticsBrowser['identify']>[2]
): Promise<void> {
  try {
    const user = await analytics.user()
    if (id === user?.id()) {
      return
    }
    await analytics?.identify(id, { email }, callback)
  } catch (error) {
    console.warn('Analytics error - identify', error)
  }
}
