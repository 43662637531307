import { QuotaType } from '@bpinternal/const'
import { Dialog, Text } from '@radix-ui/themes'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '../features/usage/constants'
import { Link } from '../elementsv2'
import { showConfirmationPrompt } from '@bpinternal/ui-kit'

//TODO: This could use some refinement, but it's a good start
export function showUpsellDialog({ quota, workspaceId }: { quota: QuotaType; workspaceId: string }) {
  return showConfirmationPrompt(
    <Text>
      You have reached your {QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[quota].name} quota. Please{' '}
      <Dialog.Close>
        <Link to="/workspaces/$workspaceId/billing/plans" params={{ workspaceId }}>
          upgrade your plan
        </Link>
      </Dialog.Close>{' '}
      or add an{' '}
      <Dialog.Close>
        <Link to="/workspaces/$workspaceId/billing" params={{ workspaceId }}>
          add-on
        </Link>
      </Dialog.Close>{' '}
      to continue using this feature.
    </Text>,
    {
      title: 'Upgrade your plan',
      cancelLabel: 'Not now',
      confirmLabel: 'Upgrade',
    }
  )
}
