import { Flex, Grid, Link, Separator, Text, TextField, Tooltip } from '@radix-ui/themes'
import { Link as RouterLink } from '@tanstack/react-router'
import { useState } from 'react'
import { Spinner, PageSection, Icon } from '~/elementsv2'
import { type BillingVersion, Plan, PlanAddOn, ProductWithPrice, useBilling } from '~/features/usage/useBillingLegacy'
import { nanoToBase } from '~/utils'
import { BillingReadonlyCallout } from '~/features/billing/componentsV2'
import { useChangeAiSpendQuota, useCheckout, useCustomerPortal, useQueryKeys, useUsage } from '~/hooks'
import { billingPlan } from '~/features/billing/constants'
import { useQuery } from '@tanstack/react-query'
import { upcomingInvoiceQueryOptions } from '~/queries/billing'
import { trackEvent } from '~/providers'
import { AnalyticEvent } from '~/providers/SegmentProvider'
import { useSearchPromoCode } from '~/hooks/billing/usePromoCodeSearch'
import { useSetPromoCode } from '~/hooks/billing/useSetPromoCode'
import type { PromoCode } from '~/features/billing/services'
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi'
import cx from 'classnames'
import { Card, Button, showConfirmationPrompt, ThemeColor } from '@bpinternal/ui-kit'
import { Check, X } from 'lucide-react'

type BillingProps = ReturnType<typeof useBilling>
/**
 *
 * @deprecated use /summary instead. This is a legacy route that is being used for gradual rollout of yearly billing
 */
export function SummaryLegacy({ workspaceId }: { workspaceId: string }) {
  const billingProps = useBilling(workspaceId)
  const billingReadonly = billingProps.billingInfo?.billingReadonly
  //TODO: This spinner has been done the lazy way, it should be replaced with a skeleton loader
  return billingProps.isLoading ? (
    <Flex width={'100%'} align={'center'} justify={'center'} height={'9'}>
      <Spinner />
    </Flex>
  ) : (
    <Flex direction={'column'} gap={'8'}>
      {billingReadonly && <BillingReadonlyCallout />}
      <BillingSummarySection billingReadonly={billingReadonly} workspaceId={workspaceId} {...billingProps} />
      <PlansSection
        billingVersion={billingProps.billingVersion}
        plan={billingProps.plan}
        currentMonthAddons={billingProps.currentMonthAddOns}
        nextMonth={billingProps.nextMonth}
        workspaceId={workspaceId}
      />
    </Flex>
  )
}

// Backend currently throws an error when trying to fetch the billing information and the customer does not have a subscription, so we need to handle this case in the frontend
const BillingSummarySection = ({
  hasActiveSubscription,
  plan,
  products,
  promoCode,
  billingReadonly,
  workspaceId,
  ...rest
}: BillingProps & { plan: Plan; billingReadonly?: boolean; workspaceId: string }) => {
  const { ai_spend } = useUsage({
    workspaceId,
    quotas: ['ai_spend'],
    period: new Date().toISOString().split('T')[0] ?? '',
  })
  const { data } = useQuery(upcomingInvoiceQueryOptions(workspaceId))
  const { mutate: updateAiSpendQuota, isPending: isUpdatingAiSpendQuota } = useChangeAiSpendQuota()
  const { mutate: openCustomerPortal } = useCustomerPortal(workspaceId)
  const { mutate: checkout, isPending: isCheckingOut } = useCheckout(workspaceId, {
    hasActiveSubscription,
    promoCode,
    ...rest,
  })

  const [newAiSpendQuota, setNewAiSpendQuota] = useState<number | null>(null)

  function handleUpdateAiSpendQuota() {
    const baseEvent: AnalyticEvent = {
      type: 'update_ai_spend',
      workspaceId,
      newAiSpend: newAiSpendQuota ?? 0,
      prevAiSpend: nanoToBase(ai_spend.quota),
      plan,
      state: 'clicked',
    } as const
    trackEvent({ ...baseEvent, state: 'clicked' })
    if (hasActiveSubscription) {
      return showConfirmationPrompt(
        <Text>
          Are you sure you want to update your AI Spend Limit to{' '}
          <Text weight={'bold'}>${Number(newAiSpendQuota ?? 0)}</Text>?
        </Text>,
        {
          title: 'Update AI Spend Limit',
          confirmLabel: 'Update',
        }
      ).then(() => {
        trackEvent({ ...baseEvent, state: 'confirmed' })
        updateAiSpendQuota({
          workspaceId,
          monthlySpendingLimit: newAiSpendQuota ?? undefined,
          onSuccess: () => setNewAiSpendQuota(null),
        })
      })
    }

    trackEvent({ ...baseEvent, state: 'credit_card_required' })
    return showConfirmationPrompt('Please add a credit card to increase your AI Spend limit.', {
      confirmLabel: 'Subscribe',
      title: 'Credit card required',
    }).then(() => {
      trackEvent({ ...baseEvent, state: 'clicked_add_card' })
      checkout()
    })
  }

  return (
    <Card className="p-4 @container">
      <Grid className="grid-cols-1 @lg:grid-cols-[1fr,auto,1fr,auto,1fr]" gap={'6'}>
        <Flex direction={'column'} gap={'3'}>
          <Text size={'2'}>Your next payment</Text>
          <Text size={'5'} weight={'medium'}>
            ${((data?.total ?? 0) / 100).toFixed(2)}
          </Text>
          <Text size={'1'}>
            <Text color="gray">This amount includes the current spend on metered </Text>
            <Link
              href="https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them"
              target="_blank"
              rel="noreferrer"
              color={ThemeColor}
            >
              AI usage.
            </Link>
          </Text>
        </Flex>
        <Separator className="@lg:h-full @lg:w-px" size="4" />
        <Flex direction={'column'} gap={'3'}>
          <Text size={'2'}>AI Spend Limit</Text>
          <Text size={'5'} weight={'medium'}>
            ${nanoToBase(ai_spend.quota).toFixed(2)}
          </Text>
          <Flex gap={'1'}>
            <TextField.Root
              className="grow"
              disabled={isUpdatingAiSpendQuota || isCheckingOut || !hasActiveSubscription}
              value={newAiSpendQuota ?? ''}
              onChange={(e) => setNewAiSpendQuota(e.target.value ? Number(e.target.value) : null)}
              type="number"
              size={'1'}
              placeholder="10.00"
            >
              <TextField.Slot>
                <Text size={'1'}>$</Text>
              </TextField.Slot>
            </TextField.Root>
            <Button
              size={'1'}
              disabled={(!newAiSpendQuota && hasActiveSubscription) || billingReadonly}
              loading={isUpdatingAiSpendQuota || isCheckingOut}
              onClick={() => {
                void handleUpdateAiSpendQuota()
              }}
              className="grid place-content-center"
            >
              <Text>{hasActiveSubscription ? 'Update' : 'Subscribe'}</Text>
            </Button>
          </Flex>
          <Text size={'1'}>
            <Text color="gray">Maximum monthly limit for bot actions that use AI. </Text>
            <Link color={ThemeColor} target="_blank" href="http://botpress.com/pricing" rel="noreferrer">
              Learn more
            </Link>
          </Text>
        </Flex>
        <Separator className="@lg:h-full @lg:w-px" size="4" />
        <Flex direction={'column'} gap={'4'}>
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'}>Payment information</Text>
            <Link onClick={() => openCustomerPortal()} className="hover:cursor-pointer hover:underline" size={'1'}>
              View invoices and payment information
            </Link>
          </Flex>
          {hasActiveSubscription && (
            <PromoCodeCard
              products={products}
              promoCode={promoCode}
              workspaceId={workspaceId}
              isCheckingOut={isCheckingOut}
            />
          )}
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'}>Need help with billing?</Text>
            <Text size={'1'}>
              <span>Email: </span>
              <Link href="mailto: finance@botpress.com" className="hover:cursor-pointer hover:underline" size={'1'}>
                finance@botpress.com
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Grid>
    </Card>
  )
}

const getPlanColor = (plan: Plan) => {
  switch (plan) {
    case 'team':
      return 'grass'
    case 'enterprise':
      return 'iris'
    case 'plus':
      return 'indigo'
    default:
      return 'gray'
  }
}

const PromoCodeCard = ({
  workspaceId,
  promoCode,
  isCheckingOut,
  products = [],
  billingReadonly,
}: {
  workspaceId: string
  promoCode: PromoCode | null
  isCheckingOut: boolean
  products?: ProductWithPrice[]
  billingReadonly?: boolean
}) => {
  const [newPromoCode, setNewPromoCode] = useState<string | null>(null)
  const { promoCodeResult, invalid } = useSearchPromoCode({
    workspaceId,
    query: newPromoCode ?? '',
  })
  const { mutate: updatePromoCode, isPending: isUpdatingPromoCode } = useSetPromoCode({ workspaceId })

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const computeDescription = (promoCode: PromoCode) => {
    const {
      coupon: { durationInMonths, percentOff, appliesTo },
    } = promoCode
    const months = durationInMonths === 1 ? 'month' : 'months'
    let description = `${Math.trunc(percentOff)}% off for ${durationInMonths} ${months}`

    if (appliesTo.length > 0) {
      const appliedProducts = products.filter((p) => appliesTo.includes(p.id))
      const productNames = appliedProducts.map((p) => p.name).join(', ')
      description += ` on ${productNames}`
    }

    return description
  }

  return (
    <div>
      {promoCode && (
        <Flex gap={'1'} direction={'column'}>
          <Text size={'2'}>Current promotion</Text>
          <Text size={'1'}>{computeDescription(promoCode)}</Text>
        </Flex>
      )}
      {!promoCode && (
        <div>
          <Text size={'2'}>Add Promo Code</Text>
          <Flex gap={'1'}>
            <TextField.Root
              className="grow"
              disabled={isUpdatingPromoCode || isCheckingOut}
              value={newPromoCode ?? ''}
              onChange={(e) => setNewPromoCode(e.target.value ?? null)}
              type="text"
              size={'1'}
            />
            <Button
              size={'1'}
              disabled={!promoCodeResult || invalid || billingReadonly}
              loading={isUpdatingPromoCode || isCheckingOut}
              onClick={() => {
                if (!newPromoCode) {
                  return
                }

                updatePromoCode({ code: newPromoCode })
              }}
              className="grid place-content-center"
            >
              <Text>Add</Text>
            </Button>
          </Flex>
          <Text size={'1'} color={invalid && newPromoCode ? 'red' : undefined}>
            {promoCodeResult
              ? computeDescription(promoCodeResult)
              : invalid && newPromoCode
                ? 'This code is invalid.'
                : null}
          </Text>
        </div>
      )}
    </div>
  )
}

const PlansSection = ({
  plan,
  currentMonthAddons,
  nextMonth,
  billingVersion,
  workspaceId,
}: {
  plan: Plan
  currentMonthAddons: PlanAddOn[]
  nextMonth?: { plan: Plan; addons: PlanAddOn[] }
  billingVersion: BillingVersion
  workspaceId: string
}) => {
  return (
    <PageSection
      className="@container"
      title={
        <Flex align={'center'} gap={'3'}>
          <Text>Your Plan</Text>
          <Link ml={'auto'} size={'1'} href="https://botpress.com/pricing" target="_blank" rel="noreferrer">
            Compare Plans
          </Link>
          <RouterLink to="/workspaces/$workspaceId/billing/plans" params={{ workspaceId }}>
            <Button size={'1'}>{plan === 'community' ? 'Upgrade' : 'Change'}</Button>
          </RouterLink>
        </Flex>
      }
    >
      <Grid className={cx(`grid @lg:grid-cols-${nextMonth ? 2 : 1} gap-4`)}>
        <MonthPlanColumn
          title={nextMonth ? 'This month' : undefined}
          plan={plan}
          addOns={currentMonthAddons}
          billingVersion={billingVersion}
        />
        {nextMonth && (
          <MonthPlanColumn
            title="Next month"
            plan={nextMonth.plan}
            addOns={nextMonth.addons}
            tooltipMsg="Downgrades are only applied next month"
            billingVersion={billingVersion}
          />
        )}
      </Grid>
    </PageSection>
  )
}

const MonthPlanColumn = ({
  title,
  plan,
  addOns,
  tooltipMsg,
  billingVersion,
}: {
  title?: string
  plan: Plan
  addOns?: PlanAddOn[]
  tooltipMsg?: string
  billingVersion: BillingVersion
}) => {
  const isLegacyPricing = billingVersion === 'v1' || billingVersion === 'v2'
  return (
    <div>
      <div className="mb-1 flex flex-row gap-1">
        {title && <div>{title}</div>}
        {tooltipMsg && (
          <Tooltip content={tooltipMsg}>
            <Icon icon={HiOutlineQuestionMarkCircle} />
          </Tooltip>
        )}
      </div>
      <Card
        data-accent-color={getPlanColor(plan)}
        data-enterprise={plan === 'enterprise' ? true : undefined}
        className="group flex flex-col gap-3 border-accent-4 p-3 @container "
      >
        <div className="-mx-3 -mt-3 bg-accent-3 p-3">
          <Text weight={'medium'} className="text-accent-11">
            {billingPlan[plan].name}
          </Text>
        </div>
        <Grid className="grid-cols-1 gap-8 p-2 @lg:grid-cols-2 @lg:gap-4">
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'} weight={'medium'} color="gray">
              Included:
            </Text>
            {billingPlan[plan].inclusions[billingVersion].map((feature) => (
              <Flex key={feature} align={'start'} gap={'2'}>
                <Check className="h-5 flex-none stroke-[1.5px] text-grass-9" />
                <Text size={'2'}>{feature}</Text>
              </Flex>
            ))}
          </Flex>
          {billingPlan[plan].exclusions.length > 0 ? (
            <Flex direction={'column'} gap={'3'}>
              <Text size={'2'} weight={'medium'} color="gray">
                Not included:
              </Text>
              {billingPlan[plan].exclusions.map((feature) => (
                <Flex key={feature} align={'start'} gap={'2'}>
                  <X className="h-5 flex-none text-red-9" />
                  <Text size={'2'}>{feature}</Text>
                </Flex>
              ))}
            </Flex>
          ) : null}

          {(addOns?.length ?? 0) > 0 && (
            <Flex direction={'column'} gap={'3'}>
              <Text size={'2'} weight={'medium'} color="gray">
                Add-ons:
              </Text>
              {addOns
                ?.sort((a: { productId: string }, b: { productId: string }) => a.productId.localeCompare(b.productId))
                .map(({ productId, name, quantity }) => (
                  <Flex key={productId} align={'start'} gap={'2'}>
                    <Check className="h-5 flex-none stroke-[1.5px] text-grass-9" />
                    <Text size={'2'}>{quantity} x</Text>
                    <Text size={'2'}>{name}</Text>
                  </Flex>
                ))}
            </Flex>
          )}
        </Grid>
        <Link
          className="p-2"
          size={'2'}
          underline="always"
          color={ThemeColor}
          href="https://botpress.com/pricing"
          target="_blank"
          rel="noreferrer"
        >
          See all features and compare plans
        </Link>
        {isLegacyPricing && (
          <Text size={'2'} color="gray" className="pl-2">
            * This workspace is subject to legacy pricing and may not reflect prices listed on the Botpress website.
            Please refer to{' '}
            {
              <Link
                underline="always"
                color={ThemeColor}
                href="https://botpress.com/pricing#price-faq"
                target="_blank"
                rel="noreferrer"
              >
                this page
              </Link>
            }{' '}
            for more information
          </Text>
        )}
      </Card>
    </div>
  )
}
