import { forwardRef, type ComponentProps, type FC, type ReactNode } from 'react'
import {
  menuItem,
  menuItemIcon,
  navbarList,
  separatorContainer,
  navMenuRoot,
  separator,
  label,
  menuItemIconCollapsed,
} from './nav-menu.module.css'
import { clsx } from 'clsx'
import { NavigationMenu } from 'radix-ui'
import { Box, Flex, Separator as RadixSeparator, Text, Button, Tooltip } from '@radix-ui/themes'
import type { Color } from '../../utils'
import { IconButton } from '../IconButton'
import { CircleHelp } from 'lucide-react'

type Props = {
  className?: string
  children?: React.ReactNode
  color?: Color
  collapsed?: boolean
} & Pick<ComponentProps<typeof NavigationMenu.Root>, 'orientation'>

const Root = forwardRef<HTMLDivElement, Props>(({ children, className, orientation, color, collapsed = true }, ref) => (
  <NavigationMenu.Root
    data-accent-color={color}
    data-collapsed={collapsed}
    orientation={orientation}
    className={navMenuRoot}
  >
    <NavigationMenu.List asChild className={className}>
      <Flex className={navbarList} ref={ref} direction={orientation === 'vertical' ? 'column' : 'row'}>
        {children}
      </Flex>
    </NavigationMenu.List>
  </NavigationMenu.Root>
))

Root.displayName = 'NavMenu.Root'

type LinkProps = {
  isActive?: boolean
  children?: React.ReactNode
} & ComponentProps<'a'>

const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ isActive, children }, ref) => (
  <NavigationMenu.Item asChild>
    {children ? (
      <NavigationMenu.Link ref={ref} asChild active={isActive}>
        {children}
      </NavigationMenu.Link>
    ) : (
      <NavigationMenu.Link ref={ref} active={isActive}></NavigationMenu.Link>
    )}
  </NavigationMenu.Item>
))

Link.displayName = 'NavMenu.Link'

type MenuItemProps = {
  Icon?: FC<{ className?: string; size?: number | string }>
  children?: ReactNode
  className?: string
  color?: Color
  active?: boolean
  trailing?: ReactNode
  open?: boolean
} & Omit<ComponentProps<typeof Button>, 'variant'>

const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  ({ className, Icon = CircleHelp, active = false, trailing, children, open = true, ...props }, ref) => {
    return open ? (
      <Button {...props} ref={ref} data-active={active} variant="ghost" className={clsx(className, menuItem)}>
        <Flex align={'center'} gap={'2'}>
          {
            <Flex>
              <Icon className={menuItemIcon} />
            </Flex>
          }
          <Flex align={'center'} gap={'2'}>
            {children}
            {trailing}
          </Flex>
        </Flex>
      </Button>
    ) : (
      <Tooltip content={children} side="right">
        <IconButton
          {...props}
          ref={ref}
          size={'2'}
          data-active={active}
          variant="ghost"
          className={clsx(className, menuItemIconCollapsed)}
          icon={Icon}
        />
      </Tooltip>
    )
  }
)

MenuItem.displayName = 'NavMenu.MenuItem'

type SeparatorProps = {
  className?: string
}
const Separator = forwardRef<HTMLDivElement, SeparatorProps>(({ className }, ref) => (
  <Box className={clsx(className, separatorContainer)}>
    <RadixSeparator size={'4'} ref={ref} className={separator} />
  </Box>
))

Separator.displayName = 'NavMenu.Separator'

type LabelProps = {
  className?: string
  children: ReactNode
}

const Label = forwardRef<HTMLSpanElement, LabelProps>(({ className, children }, ref) => (
  <Text ref={ref} color="gray" size={'1'} className={clsx(className, label)}>
    {children}
  </Text>
))

Label.displayName = 'NavMenu.Label'

export const NavMenu = {
  Root,
  Link,
  MenuItem,
  Separator,
  Label,
}
