import { createContext, useContext, useState } from 'react'

type PortalContextType = {
  bannerPortalRef: HTMLDivElement | null
  setBannerPortalRef: (node: HTMLDivElement | null) => void
  pageActionsPortalRef: HTMLDivElement | null
  setPageActionsPortalRef: (node: HTMLDivElement | null) => void
}

// Create Context
const PortalContext = createContext<PortalContextType | undefined>(undefined)

// Custom Hook for Accessing the Portal Context
export const usePortal = () => {
  const context = useContext(PortalContext)
  if (!context) {
    throw new Error('usePortal must be used within a PortalProvider')
  }
  return context
}

// Context Provider Component
export const PortalProvider = ({ children }: { children: React.ReactNode }) => {
  const [bannerPortalRef, setBannerPortalRef] = useState<HTMLDivElement | null>(null)
  const [pageActionsPortalRef, setPageActionsPortalRef] = useState<HTMLDivElement | null>(null)

  return (
    <PortalContext.Provider
      value={{ bannerPortalRef, setBannerPortalRef, pageActionsPortalRef, setPageActionsPortalRef }}
    >
      {children}
    </PortalContext.Provider>
  )
}
