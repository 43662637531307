/**
 * Get the Country based on the users IP address
 * @returns - The country of the user
 */
export const getCountry = async () => {
  return await fetch('https://ipapi.co/json/')
    .then((response) => response.json() as unknown as { country_name: string })
    .then((data) => data.country_name)
    .catch(() => 'unknown')
}
