import { useBilling } from '~/features/usage/useBilling'
import { useMutation } from '@tanstack/react-query'
import { getQueryKey } from '~/services'
import { showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'

import { queryClient } from '~/providers/ReactQuery'
import type { ProductQuantityMap } from '~/features/usage/useBilling'

type useYearlyCheckoutBillingProps = Pick<ReturnType<typeof useBilling>, 'generateCheckoutSession'>
export function useYearlyCheckout(
  workspaceId: string,
  billingProps: useYearlyCheckoutBillingProps,
  onUpdateSubscription?: () => void
) {
  const { generateCheckoutSession } = billingProps

  return useMutation({
    gcTime: 0,
    mutationFn: async (plan: ProductQuantityMap[string]) => {
      const { url } = await generateCheckoutSession({ [plan.productDetails.id]: plan })
      await queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_/$workspaceId_', { workspaceId }) })
      window.location.href = url
      return
    },
    onError: (error) => {
      showErrorToast(error.message ?? 'There was an error while updating your subscription. Please try again.')
    },
    meta: {
      suppressError: true,
    },
  })
}
