import { useMemo } from 'react'
import { PLUS_PLAN_PRODUCT_ID, TEAM_PLAN_PRODUCT_ID, useBilling } from '~/features/usage/useBilling'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { showErrorToast, showSuccessToast } from '@bpinternal/ui-kit'
import { getQueryKey } from '~/services'
import { isEqual } from 'lodash'
import { queryClient } from '~/providers/ReactQuery'
import { computeTotalCost } from '~/features/billing/utils'
import { BillingApiClient } from '~/features/billing/services'
import { ProductQuantityMap } from '~/features/usage/useBillingLegacy'

type useMonthlyCheckoutBillingProps = Pick<
  ReturnType<typeof useBilling>,
  | 'activePlanId'
  | 'hasActiveSubscription'
  | 'initialActivePlanId'
  | 'productQuantityMap'
  | 'initialProductQuantityMap'
  | 'subscription'
  | 'generateCheckoutSession'
  | 'promoCode'
  | 'billingVersion'
>
export function useMonthlyCheckout(
  workspaceId: string,
  billingProps: useMonthlyCheckoutBillingProps,
  onUpdateSubscription?: () => void
) {
  const {
    activePlanId,
    hasActiveSubscription,
    initialActivePlanId,
    productQuantityMap,
    initialProductQuantityMap,
    subscription,
    generateCheckoutSession,
    promoCode,
    billingVersion,
  } = billingProps

  const navigate = useNavigate()

  const totalPrice = useMemo(
    () => computeTotalCost(productQuantityMap, promoCode),
    [productQuantityMap, activePlanId, promoCode]
  )
  const initialTotalPrice = useMemo(
    () => computeTotalCost(productQuantityMap),
    [initialProductQuantityMap, initialActivePlanId]
  )

  const subscriptionConfigHasChanged = useMemo(() => {
    return initialActivePlanId !== activePlanId || !isEqual(productQuantityMap, initialProductQuantityMap)
  }, [productQuantityMap, initialProductQuantityMap, activePlanId, initialActivePlanId])

  return useMutation({
    gcTime: 0,
    mutationFn: async () => {
      if (!activePlanId || (hasActiveSubscription && !subscriptionConfigHasChanged)) {
        return null
      }

      if (!subscription) {
        const { url } = await generateCheckoutSession(productQuantityMap)
        await queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_/$workspaceId_', { workspaceId }) })
        window.location.href = url
        return
      }

      const payload = Object.entries(productQuantityMap)
        .filter(([_, product]) => product?.subscriptionLineItemId || product?.quantity)
        .map(([_, product]) => ({
          price: product?.priceId,
          quantity: product?.quantity ?? 0,
        }))

      await BillingApiClient.updateSubscription(workspaceId, {
        productQuantityConfig: payload,
        isUpgrading: initialTotalPrice <= totalPrice,
        subscriptionId: subscription.id,
      })

      const isUpgradeToPlus = isUpgradeToPlusPlan(initialProductQuantityMap, productQuantityMap)
      const isUpgradeToTeam = isUpgradeToTeamPlan(initialProductQuantityMap, productQuantityMap)

      const isPlanUpgrade = isUpgradeToPlus || isUpgradeToTeam

      void queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_/$workspaceId_', { workspaceId }) })

      if (isPlanUpgrade && subscription) {
        const plan = isUpgradeToPlus ? 'plus' : 'team'
        navigate({ to: '/welcome', search: { billingVersion, plan } })
      } else {
        showSuccessToast('Your subscription has been updated successfully.')
        onUpdateSubscription?.()
      }
    },
    onError: (error) => {
      showErrorToast(error.message ?? 'There was an error while updating your subscription. Please try again.')
    },
    meta: {
      suppressError: true,
    },
  })
}

const getPlanStatus = (
  productId: string,
  initialProductQuantityMap: ProductQuantityMap,
  productQuantityMap: ProductQuantityMap
) => ({
  hadPlan: (initialProductQuantityMap[productId]?.quantity ?? 0) > 0,
  hasPlan: (productQuantityMap[productId]?.quantity ?? 0) > 0,
})

const isUpgradeToTeamPlan = (initialProductQuantityMap: ProductQuantityMap, productQuantityMap: ProductQuantityMap) => {
  const { hadPlan: hadTeamPlan, hasPlan: hasTeamPlan } = getPlanStatus(
    TEAM_PLAN_PRODUCT_ID,
    initialProductQuantityMap,
    productQuantityMap
  )
  return hasTeamPlan && !hadTeamPlan
}

const isUpgradeToPlusPlan = (initialProductQuantityMap: ProductQuantityMap, productQuantityMap: ProductQuantityMap) => {
  const { hadPlan: hadTeamPlan } = getPlanStatus(TEAM_PLAN_PRODUCT_ID, initialProductQuantityMap, productQuantityMap)
  const { hasPlan: hasPlusPlan, hadPlan: hadPlusPlan } = getPlanStatus(
    PLUS_PLAN_PRODUCT_ID,
    initialProductQuantityMap,
    productQuantityMap
  )
  return hasPlusPlan && !hadPlusPlan && !hadTeamPlan
}
