import { Portal } from '@radix-ui/themes'
import { usePortal } from '../providers'

type Props = {
  children?: React.ReactNode
}
export const PageActionPortal = (props: Props) => {
  const { pageActionsPortalRef } = usePortal()

  return <Portal {...props} className="flex gap-2" container={pageActionsPortalRef} />
}
