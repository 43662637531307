import * as v from 'valibot'

const configSchema = v.object({
  botName: v.optional(v.string()),
  botAvatar: v.optional(v.string()),
  botDescription: v.optional(v.string()),
  phone: v.fallback(v.optional(v.object({ title: v.optional(v.string()), link: v.optional(v.string()) })), undefined),
  email: v.fallback(v.optional(v.object({ title: v.optional(v.string()), link: v.optional(v.string()) })), undefined),
  website: v.fallback(v.optional(v.object({ title: v.optional(v.string()), link: v.optional(v.string()) })), undefined),
  termsOfService: v.fallback(
    v.optional(v.object({ title: v.optional(v.string()), link: v.optional(v.string()) })),
    undefined
  ),
  privacyPolicy: v.fallback(
    v.optional(v.object({ title: v.optional(v.string()), link: v.optional(v.string()) })),
    undefined
  ),
  composerPlaceholder: v.optional(v.string()),
  color: v.optional(v.string()),
  radius: v.optional(v.number()),
  themeMode: v.fallback(v.optional(v.picklist(['light', 'dark'])), undefined),
  variant: v.fallback(v.optional(v.picklist(['solid', 'soft'])), undefined),
  fontFamily: v.fallback(v.optional(v.picklist(['rubik', 'inter', 'ibm', 'fira'])), undefined),
  showPoweredBy: v.fallback(v.optional(v.boolean()), undefined),
  additionalStylesheet: v.optional(v.string()),
  additionalStylesheetUrl: v.optional(v.string()),
  allowFileUpload: v.fallback(v.optional(v.boolean()), undefined),
})

export const webchatConfigSchema = v.object({
  clientId: v.string(),
  configuration: configSchema,
})

export const webchatIntegrationInputSchema = v.object({
  botDisplayName: v.optional(v.string()),
  botAvatarUrl: v.optional(v.string()),
  botDescription: v.optional(v.string()),
  descriptionPhoneNumber: v.optional(
    v.object({
      title: v.optional(v.string()),
      link: v.optional(v.string()),
    })
  ),
  descriptionEmailAddress: v.optional(
    v.object({
      title: v.optional(v.string()),
      link: v.optional(v.string()),
    })
  ),
  descriptionWebsiteUrl: v.optional(
    v.object({
      title: v.optional(v.string()),
      link: v.optional(v.string()),
    })
  ),
  termsConditionsUrl: v.optional(
    v.object({
      title: v.optional(v.string()),
      link: v.optional(v.string()),
    })
  ),
  privacyPolicyUrl: v.optional(
    v.object({
      title: v.optional(v.string()),
      link: v.optional(v.string()),
    })
  ),
  botComposerPlaceholder: v.optional(v.string()),
  showPoweredByBotpress: v.optional(v.boolean()),
  // additionalStylesheet: v.optional(v.string()), // TODO: Remove this in November 2025
  additionalStylesheetUrl: v.optional(v.string()),
  allowedOrigins: v.optional(v.array(v.string())),
  primaryColor: v.optional(v.string()),
  borderRadiusScale: v.optional(v.number()),
  themeMode: v.optional(v.picklist(['light', 'dark'])),
  variant: v.optional(v.picklist(['solid', 'soft'])),
  fontFamily: v.optional(v.string()),
  showPoweredBy: v.optional(v.boolean()),
  allowFileUpload: v.optional(v.boolean()),
})

export type WebchatIntegrationInput = v.InferOutput<typeof webchatIntegrationInputSchema>

export const webchatConfigToWebchatIntegrationInput = v.pipe(
  configSchema,
  v.transform((webchatConfig) => {
    return {
      botDisplayName: webchatConfig.botName,
      botAvatarUrl: webchatConfig.botAvatar,
      botDescription: webchatConfig.botDescription,
      descriptionPhoneNumber: webchatConfig.phone,
      descriptionEmailAddress: webchatConfig.email,
      descriptionWebsiteUrl: webchatConfig.website,
      termsConditionsUrl: webchatConfig.termsOfService,
      privacyPolicyUrl: webchatConfig.privacyPolicy,
      botComposerPlaceholder: webchatConfig.composerPlaceholder,
      showPoweredByBotpress: webchatConfig.showPoweredBy, // validate if this can be removed
      additionalStylesheet: webchatConfig.additionalStylesheet,
      additionalStylesheetUrl: webchatConfig.additionalStylesheetUrl,
      primaryColor: webchatConfig.color,
      borderRadiusScale: webchatConfig.radius,
      themeMode: webchatConfig.themeMode,
      variant: webchatConfig.variant,
      fontFamily: webchatConfig.fontFamily,
      showPoweredBy: webchatConfig.showPoweredBy,
      allowFileUpload: webchatConfig.allowFileUpload,
    }
  })
)

export type WebchatConfig = v.InferOutput<typeof webchatConfigSchema>['configuration']
